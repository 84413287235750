import React from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';

export default function Navbar() {

	let publicUrl = process.env.PUBLIC_URL+'/'
        return (
		<div>
           <header className="ltn__header-area ltn__header-5 ltn__header-transparent--- gradient-color-4---">
			<div className="ltn__header-top-area section-bg-6 top-area-color-white---">
				<div className="container">
				<div className="row">
					<div className="col-md-12">
					<div className="top-bar-right text-end">
						<div className="ltn__top-bar-menu">
						<ul>
							<li>
								<Social />
							</li>
							<li>
							{/* header-top-btn */}
							<div className="header-top-btn">
								<a href="https://wa.me/525574308182?text=Quiero+ponerme+en+contacto"><i className="fab fa-whatsapp" />Contactanos</a>
								<Link to="/contacto"></Link>
							</div>
							</li>
						</ul>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
			<div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white">
				<div className="container">
				<div className="row">
					<div className="col">
					<div className="site-logo-wrap">
						<div className="site-logo go-top">
						<Link to="/"><img src={publicUrl+"assets/img/logo2.png"} alt="la Boutique de Inmuebles" /></Link>
						</div>
						<div className="get-support clearfix d-none">
						<div className="get-support-icon">
							<i className="icon-call" />
						</div>
						</div>
					</div>
					</div>
					<div className="col header-menu-column">
					<div className="header-menu d-none d-xl-block">
						<nav>
						<div className="ltn__main-menu go-top">
							<ul>
							<li><Link to="/">Inicio</Link></li>
							<li><Link to="/servicios">Nuestros servicios</Link></li>
							<li className="menu-icon"><Link to="/propiedades">Inmuebles</Link></li>
							<li><Link to="/blog-grid">LaBouTIPS</Link></li>
							<li><Link to="/contacto">¿Donde estámos?</Link></li>

							</ul>
						</div>
						</nav>
					</div>
					</div>
					<div className="col ltn__header-options ltn__header-options-2 mb-sm-20">
					
					{/* Mobile Menu Button */}
					<div className="mobile-menu-toggle d-xl-none">
						<a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle">
						<svg viewBox="0 0 800 600">
							<path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
							<path d="M300,320 L540,320" id="middle" />
							<path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
						</svg>
						</a>
					</div>
					</div>
				</div>
				</div>
			</div>
			</header>
			<div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu">
				<div className="ltn__utilize-menu-inner ltn__scrollbar">
					<div className="ltn__utilize-menu-head">
					<div className="site-logo">
						<Link to="/"><img src={publicUrl+"assets/img/logo.png"} alt="La Boutique de Inmuebles" /></Link>
					</div>
					<button className="ltn__utilize-close">×</button>
					</div>
					<div className="ltn__utilize-menu">
					<ul>
						<li><a href="/">Inicio</a></li>
						<li><Link to="/servicios">Nuestros Servicios</Link></li>
						<li><Link to="/propiedades">Inmuebles</Link></li>
						<li><Link to="/blog-grid">LaBouTIPS</Link></li>
						<li><Link to="/contacto">Contactanos</Link></li>
					</ul>
					</div>
					<div className="ltn__social-media-2">
					<ul>
						<li><a href="https://www.facebook.com/laboutiquedeinmueblesmx" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
						<li><a href="https://www.linkedin.com/in/la-boutique-de-inmuebles-528097213" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
						<li><a href="https://www.instagram.com/laboutiquedeinmueblesmx/" title="Instagram"><i className="fab fa-instagram" /></a></li>
					</ul>
					</div>
				</div>
			</div>
		</div>
        )
}
