import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../blog-components/sidebar';
import NavBar from '../global-components/navbar';
import PageHeader from '../global-components/page-header';
import Footer from '../global-components/footer';

export default function ArticuloHogar() {

    let publicUrl = process.env.PUBLIC_URL+'/';

    return(
		<>
		<NavBar></NavBar>

		<div className={"ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image "} data-bs-bg={publicUrl+"assets/img/blog/1.jpg"}>
			<div className="container">
				<div className="row">
				<div className="col-lg-12">
					<div className="ltn__breadcrumb-inner">
					<h1 className="page-title">La BouTips</h1>
					<div className="ltn__breadcrumb-list">
						<ul>
						<li><Link to="/"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Inicio</Link></li>
						<li></li>
						</ul>
					</div>
					</div>
				</div>
				</div>
			</div>
		</div>

        <div className="ltn__page-details-area ltn__blog-details-area mb-120">
        <div className="container">
        <div className="row">
            <div className="col-lg-8">
            <div className="ltn__blog-details-wrap">
                <div className="ltn__page-details-inner ltn__blog-details-inner">
                <div className="ltn__blog-meta">
                    <ul>
                    <li className="ltn__blog-category">
                        <Link to="/blog-grid">LaBouTips</Link>
                    </li>
                    </ul>
                </div>
					<h2 className="ltn__blog-title">
						Créditos hipotecarios, ¿Qué debes tomar en cuenta?
					</h2>
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-author go-top">
							<i className="far fa-user" />Por: Lisset Zárate - La Boutique de Inmuebles
						</li>
						<li className="ltn__blog-date">
							<i className="far fa-calendar-alt" />Octubre, 2022
						</li>
						</ul>
					</div>
					<p>
                    </p>


					<img className="alignleft" src={publicUrl+"assets/img/blog/blog-details/3.jpg"} alt="Image1" />
					<p>
						Sabemos lo difícil que puede llegar a ser escoger un crédito hipotecario, 
						ya que es un traje a la medida. La mejor recomendación que te podemos hacer es 
						acudir con los expertos que te puedan brindar una asesoría integral.
                    <br></br>
                    <br></br>
						Nosotros como expertos podemos ayudarte con este importante proceso 
						y es por eso que te compartimos 8 conceptos que debes saber, para tomar
						una mejor decisión al elegir un crédito.
                    </p>

                    <br></br>
                    <br></br>

					<h4>1. Tasa de interés</h4>
					<p>
						Es lo que te cuesta en el tiempo que el banco te preste los recursos económicos para adquirir el bien inmueble.
                    </p>

					<h4>2. Costo Anual Total (CAT)</h4>
					<p>
						Es el costo total que le pagas el banco, es decir el costo de seguros, comisiones e intereses.
					</p>

					<h4>3. Tipo de moneda</h4>
					<p>
						Puede ser en pesos, salarios mínimos o UDIS.					
					</p>


					<h4>4. Pago a capital </h4>
					<p>
						Son las amortizaciones a tu crédito hipotecario. 
					</p>

					<blockquote>
						Sabías que más de un 80% de tus mensualidades van al interés en los primeros años, (en la mayoría de los créditos).					
                    </blockquote>

					<h4>5. Plazo del crédito</h4>
					<p>
						El plazo recomendable, es de 10 años; pero te pueden prestar hasta 30 años.
					</p>

					<h4>6. Monto de aforo</h4>
					<p>
						Cantidad de dinero que te presta la institución en proporción al valor del inmueble, generalmente el aforo es del 80% del valor de la vivienda.
					</p>

					<h4>7. Ahorros</h4>
					<p>
						Recuerda que siempre tendrás que tener entre un 25% a un 30% del monto total de la operación disponible para los gastos notariales  ( avalúo, comisión por apertura, gastos de investigación y de escrituración). y cubrir el enganche del inmueble.					
					</p>

					<h4>8. Seguros</h4>
					<p>
						Todos los créditos hipotecarios tienen seguros incluidos como el de vida, daños y el seguro por quedarte sin trabajo
					</p>

					<blockquote>
						Recuerda que dar pagos anticipados a tu crédito te ayuda a disminuir el tiempo y los intereses del mismo.
                    </blockquote>


					<hr />
					<p>
                        Nuestro trabajo como asesores profesionales inmobiliarios es 
						comprender totalmente tus necesidades y asesorarte en todo el proceso de adquisición de tu inmueble.
						Contamos con asesoria personalizada en venta, renta y servicios inmobiliarios. 
                    </p>

					<img src={publicUrl+"assets/img/blog/3.jpg"} alt="2" />

                    <h4>
                        ¿Tienes dudas sobre que crédito te combiene más?
                        ¡Contáctanos! En la boutique de inmuebles con gusto te brindaremos la asesoría personalizada que requieras.
                    </h4>


					</div>
					{/* blog-tags-social-media */}
					<div className="ltn__blog-tags-social-media mt-80 row">
					<div className="ltn__tagcloud-widget col-lg-8 go-top">
						<h4>Autor: Lisset Zárate - La Boutique de Inmuebles  </h4>
						<ul>
						</ul>
					</div>
					</div>
					<hr />

					<hr />

				</div>
				</div>
				<div className="col-lg-4 go-top">
			<aside className="sidebar-area blog-sidebar ltn__right-sidebar">
				{/* Author Widget */}
				<div className="widget ltn__author-widget">
				<div className="ltn__author-widget-inner text-center">
					<img src={publicUrl+"assets/img/team/lisset.jpg"} alt="Image" />
					<h5>Lisset Zárate</h5>
					<small>La Boutique de Inmuebles</small>
					<div className="product-ratting">
					<ul>
						<li><a href="#"><i className="fas fa-star" /></a></li>
						<li><a href="#"><i className="fas fa-star" /></a></li>
						<li><a href="#"><i className="fas fa-star" /></a></li>
						<li><a href="#"><i className="fas fa-star" /></a></li>
						<li><a href="#"><i className="fas fa-star" /></a></li>

					</ul>
					</div>
				</div>
				</div>
				{/* Tagcloud Widget */}
				<div className="widget ltn__tagcloud-widget go-top">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Secciones</h4>
				<ul>
					<li><Link to="/propiedades">Ver todos los inmuebles</Link></li>
					<li><Link to="/servicios">Nuestros Servicios</Link></li>
					<li><Link to="/contacto">Contáctanos</Link></li>
				</ul>
				</div>
			</aside>
		</div>

			</div>
			</div>
		</div>
		<Footer></Footer>
		</>
    )
  }
