import {
  LOAD_PRODUCTS,
  SET_LISTVIEW,
  SET_GRIDVIEW,
  UPDATE_SORT,
  SORT_PRODUCTS,
  UPDATE_FILTERS,
  FILTER_PRODUCTS,
  CLEAR_FILTERS,
  UPDATE_ALL_FILTERS
} from '../actions';

const filter_reducer = (state, action) => {

  if (action.type === LOAD_PRODUCTS) {
    let maxPrice = action.payload.map((p) => p.price)
    let listadoHelper = [], listadoFinal = [];

    maxPrice = Math.max(...maxPrice)
    maxPrice = 0;

    action.payload.forEach(item => {
      if (listadoHelper.indexOf(item.type.code) < 0) {
        listadoHelper.push(item.type.code);
        listadoFinal.push(item.type)
      }
    });

    return {
      ...state,
      all_properties: [...action.payload],
      filtered_properties: [...action.payload],
      filters: { ...state.filters, max_price: maxPrice, price: maxPrice },
      listadoTiposPropiedad: listadoFinal
    }
  }
  if (action.type === UPDATE_SORT) {
    //return { ...state, sort: action.payload }
    return {
      ...state,
      sort: {
        select: action.payload.sort,
        checkVenta: action.payload.checkVenta,
        checkRenta: action.payload.checkRenta
      }
    }
  }

  if (action.type === SORT_PRODUCTS) {
    const { sort, filtered_properties } = state
    let tempProperties = [...filtered_properties]

    console.log(sort.searchAddress);

    //FILTRO POR TIPO VENTA 
    if (sort.checkVenta) {
      tempProperties = tempProperties.filter(itemFilter => itemFilter.operations.find(itemFind => itemFind.operation_type.toLocaleLowerCase() === 'venta'));
    }

    //FILTRO POR TIPO RENTA 
    if (sort.checkRenta) {
      tempProperties = tempProperties.filter(itemFilter => itemFilter.operations.find(itemFind => itemFind.operation_type.toLocaleLowerCase() === 'alquiler'));
    }

    // FILTRO POR RANGO DE PRECIOS
    if (sort.numRecamaras !== 'Todas') {
      tempProperties = tempProperties.filter(itemFilter => itemFilter.suite_amount === parseInt(sort.numRecamaras));
    }

    // FILTRO POR TIPO DE PROPIEDAD 
    if (sort.tipoPropiedad !== 'all') {
      tempProperties = tempProperties.filter(itemfilter => itemfilter.type.code === sort.tipoPropiedad)
    }

    // FILTRO POR ORDENAMIENTO ASC O DESC 
    if (sort.select === 'Todas') {
      tempProperties = tempProperties.sort(function (a, b) {
        if (a.created_at < b.created_at) {
          return 1;
        }
        if (a.created_at > b.created_at) {
          return -1;
        }
        return 0;
      });
    }

    if (sort.select !== 'Todas') {

      if (sort.select == 'filtro1') {
        tempProperties = tempProperties.sort(function (a, b) {
          if (a.final_price_venta > b.final_price_venta) {
            return 1;
          }
          if (a.final_price_venta < b.final_price_venta) {
            return -1;
          }
          return 0;
        });
      } else if (sort.select == 'filtro2') {
        tempProperties = tempProperties.sort(function (a, b) {
          if (a.final_price_venta < b.final_price_venta) {
            return 1;
          }
          if (a.final_price_venta > b.final_price_venta) {
            return -1;
          }
          return 0;
        });
      }else if (sort.select == 'masrecientes') {
        tempProperties = tempProperties.sort(function (a, b) {
          if (a.created_at < b.created_at) {
            return 1;
          }
          if (a.created_at > b.created_at) {
            return -1;
          }
          return 0;
        });
      }else if (sort.select == 'menosrecientes') {
        tempProperties = tempProperties.sort(function (a, b) {
          if (a.created_at > b.created_at) {
            return 1;
          }
          if (a.created_at < b.created_at) {
            return -1;
          }
          return 0;
        });
      }
    }

    // FILTR POR COLONIA O DIRECCION 
    if (sort.searchAddress) {
      // tempProperties = tempProperties.filter(
      //     itemfilter =>  itemfilter.location.full_location ? itemfilter.location.full_location.toLocaleLowerCase().include(
      //       sort.searchAddress ? sort.searchAddress.toLocaleLowerCase() : null
      //     ): null 
      // )
    }

    // FILTRO POR RANGO DE PRECIOS
    if (sort.maxPrice <= sort.precio) {
      tempProperties = tempProperties.filter(itemfilter => (itemfilter.final_price_venta >= sort.minPrice && itemfilter.final_price_venta <= sort.maxPrice))
    }


    return { ...state, filtered_properties: tempProperties }

  }

  if (action.type === UPDATE_FILTERS) {
    const { name, value } = action.payload
    return { ...state, filters: { ...state.filters, [name]: value } }
  }

  if (action.type === FILTER_PRODUCTS) {
    const { all_properties } = state
    const { text } = state.filters

    let tempProperties = [...all_properties]
    // filtering
    // text
    if (text) {
      tempProperties = tempProperties.filter((property) => {
        return property.address.toLowerCase().startsWith(text)
      })
    }

    return { ...state, filtered_properties: tempProperties }
  }

  if (action.type === CLEAR_FILTERS) {
    return {
      ...state,
      filters: {
        ...state.filters,
        text: ''
      },
    }
  }

  if (action.type === UPDATE_ALL_FILTERS) {

    if (action.payload.filtro === 'rangePrice') {
      return {
        ...state,
        sort: {
          ...state.sort,
          minPrice: action.payload.currentValue.minPrice,
          maxPrice: action.payload.currentValue.maxPrice
        },
      }
    } else if (action.payload.filtro === 'eliminarFiltros') {
      let initialFilter = {
        select: "Todas",
        checkVenta: false,
        checkRenta: false,
        numRecamaras: 'Todas',
        precio: 100000000,
        minPrice: 0,
        maxPrice: 100000000,
        tipoPropiedad: 'all'
      };

      document.getElementById("ventaID").checked = false;
      document.getElementById("rentaID").checked = false;

      return {
        ...state,
        sort: {
          ...state.sort,
          ...initialFilter
        },
      }
    } else {
      return {
        ...state,
        sort: {
          ...state.sort,
          [action.payload.filtro]: action.payload.currentValue
        },
      }
    }
  }

  throw new Error(`No Matching "${action.type}" - action type`)
}

export default filter_reducer