import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../blog-components/sidebar';
import NavBar from '../global-components/navbar';
import PageHeader from '../global-components/page-header';
import Footer from '../global-components/footer';

export default function ArticuloHogar() {

    let publicUrl = process.env.PUBLIC_URL+'/';

    return(
		<>
		<NavBar></NavBar>

		<div className={"ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image "} data-bs-bg={publicUrl+"assets/img/blog/1.jpg"}>
			<div className="container">
				<div className="row">
				<div className="col-lg-12">
					<div className="ltn__breadcrumb-inner">
					<h1 className="page-title">La BouTips</h1>
					<div className="ltn__breadcrumb-list">
						<ul>
						<li><Link to="/"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Inicio</Link></li>
						<li></li>
						</ul>
					</div>
					</div>
				</div>
				</div>
			</div>
		</div>

        <div className="ltn__page-details-area ltn__blog-details-area mb-120">
        <div className="container">
        <div className="row">
            <div className="col-lg-8">
            <div className="ltn__blog-details-wrap">
                <div className="ltn__page-details-inner ltn__blog-details-inner">
                <div className="ltn__blog-meta">
                    <ul>
                    <li className="ltn__blog-category">
                        <Link to="/blog-grid">LaBouTips</Link>
                    </li>
                    </ul>
                </div>
					<h2 className="ltn__blog-title">
                        Ambientes creados con luz.
					</h2>
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-author go-top">
							<i className="far fa-user" />Por: JCR - Two Arquitectura
						</li>
						<li className="ltn__blog-date">
							<i className="far fa-calendar-alt" />Abril 30, 2022
						</li>
						</ul>
					</div>
					<p>
                    </p>


					<img className="alignleft" src={publicUrl+"assets/img/blog/blog-details/2.jpg"} alt="Image1" />
					<p>
                        En la reflexión anterior, donde mencionaba que nuestro hogar es un ser vivo que evoluciona conforme vamos adquiriendo experiencias. También es un fiel reflejo de quien o quienes habitan el espacio.
                    <br></br>
                    <br></br>
                        ¿Cuáles son los valores y como hablan de la calidad de vida de sus moradores?                     
                    </p>

                    <br></br>
                    <br></br>

					<p>
                        Así como todos somos únicos, cada hogar tiene atmósferas particulares. La iluminación es una gran herramienta que permite imprimir un sello personal en cada espacio de nuestra propiedad; ya sea para habitar, trabajar, estudiar, producir, sanar, etc.                     
                    </p>

					<blockquote>
                        Es la luz esta materia que no podemos tocar, pero que nos permite ver todo lo que ella toca. Y esta energía luminosa tiene varias cualidades. Una de ellas es la apariencia en su tonalidad. 
                    </blockquote>


					<h2>Tonalidad en la luz.</h2>
					<p>
                    La tonalidad de la luz la podemos clasificar como luz cálida, luz neutra y luz fría o luz de día. Todas ellas nos ofrecen beneficios para cierta actividad o proyectar una imagen puntual de acuerdo con el objetivo o al concepto de interiorismo.
                    Así que comencemos a hablar sobre las distintas opciones de tonalidad de luz que están disponibles hoy en día.
                    </p>
					<img src={publicUrl+"assets/img/blog/34.jpg"} alt="2" />
					<label>Iluminación</label>

					<hr />

					<h4>
                        Luz de día o luz fría
                    </h4>
					<p>
                        Es ampliamente aceptada por las personas de mayor edad, pues perciben que hay más luz. También es preferida en zonas donde hace bastante calor, creo que se explica por sí mismo. 
                        Este tipo de iluminación que tiende hacia un tono azulado la podemos proponer en ambientes que muestren la tecnología, dinamismo, velocidad.  Todos ellos con niveles de iluminación que sean igual o superior a 500lux (ejemplo oficinas o lugares que requieran atención al detalle). Si el nivel de iluminación es bajo o se emplea dimmer para atenuar la luz, la percepción no es tan grata. Los productos que corresponden a este tipo de luz, los podemos encontrar en muchos sitios de manera habitual. Tanto en el supermercado, como en las tiendas de material eléctrico.
                    </p>

					<h4>
                        Luz Neutra
                    </h4>
					<p>
                        La puedo identificar por su claridad; porque no tiende hacia lo azul o hacia lo amarillo en su tonalidad. Esta iluminación es ampliamente usada en espacios que denotan limpieza, frescura y enfoque. Entre ellos podemos mencionar oficinas, hospitales, cocinas, gimnasios, restaurantes de comida rápida, etc. A las personas jóvenes en general les gusta mucho el ambiente que genera este tipo de luz al sentirse activos, frescos y con claridad; al mismo tiempo que los mantiene despiertos y atentos a lo que sucede alrededor.
                    </p>


                    <h4>
                        Luz Cálida
                    </h4>
					<p>
                        Este tipo de luz es empleada frecuentemente por la industria de hospitalidad (restaurantes, spas, hoteles, etc.), donde se busca que las personas que asistimos a estos lugares nos sintamos “abrazados” por los ambientes que genera. La luz cálida nos ayuda a relajarnos, a entrar en confianza (porque se reduce el nivel de alerta). La velocidad de las acciones se reduce, me ayuda a permanecer más tiempo en estos espacios. Si estoy más tiempo en un hotel, en un restaurante o bar; tenderé a consumir más alimentos y bebidas, incrementando el importe del ticket de consumo.                    
                    </p>

					<hr />
					<p>
                        Algo que es importante mencionar, es que los ambientes señalados pueden estar presente en un solo proyecto. Cubriendo distintas áreas de acuerdo con las características del uso de cada espacio. Si hablo de mi casa, a mí me gusta estar relajado en todas las áreas; por ello recurro a la iluminación cálida. En el baño me gusta una luz menos cálida para percibir más detalles en el espejo. Finamente en la cocina me gusta un ambiente con luz más clara para evitar sombras, estar alerta (respecto al fuego o cuchillos) y tener sensación de limpieza. Esto no es una regla. La iluminación debe satisfacer los gustos, requerimientos y confort de quienes habitan los espacios; de la misma manera que proyecta su personalidad.                    
                    </p>
					<p>
                        En nuestro trabajo de asesoría en proyecto de iluminación buscamos comprender totalmente cual es la función de cada espacio, conocer el proyecto y su materialidad; las condiciones de la arquitectura y claro está el presupuesto y el tiempo en que se requieren los productos. Esta información nos brinda claridad para poder discriminar las múltiples opciones de luminarias, para elegir solo las que lograrán la identidad de los ambientes del proyecto y preservarán la inversión de nuestro cliente.                     
                    </p>

					<p>
                        <h5>Como una anécdota complementaria,</h5> en alguna ocasión en un proyecto que desarrollamos para un corporativo; uno de los ejecutivos tenía la sensación de que tenía menos luz (cálida, por cierto) de la recomendada por las normas mexicanas. Tuvimos la oportunidad de realizar métricas para verificar que estábamos cumpliendo con lo que proyectamos y con los niveles solicitados por las instituciones locales. Solo se trataba de un tema de percepción.                    
                    </p>

                    <h4>
                        ¿Te identificas con algún ambiente en particular?
                        Compártenos tu sentir a través de tus comentarios. Queremos conocerte.
                    </h4>


					<div className="row">
						<div className="col-lg-6">
						<img src={publicUrl+"assets/img/service/31.jpg"} alt="3" />
						<label>TWOarquitectura</label>
						</div>
						<div className="col-lg-6">
						<img src={publicUrl+"assets/img/service/32.jpg"} alt="4" />
						</div>
					</div>


					</div>
					{/* blog-tags-social-media */}
					<div className="ltn__blog-tags-social-media mt-80 row">
					<div className="ltn__tagcloud-widget col-lg-8 go-top">
						<h4>Autor: JCR - TWOarquitectura  </h4>
						<ul>
						</ul>
					</div>
					</div>
					<hr />

					<hr />

				</div>
				</div>
				<Sidebar/>
			</div>
			</div>
		</div>
		<Footer></Footer>
		</>
    )
  }
