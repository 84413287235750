import React from 'react';
import { Link } from 'react-router-dom';

export default function Banner() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return(    
			<div className="ltn__slider-area ltn__slider-3  section-bg-1 go-top">
				  <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
				    {/* ltn__slide-item */}
				    <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3">
				      <div className="ltn__slide-item-inner">
				        <div className="container">
				          <div className="row">
				            <div className="col-lg-12 align-self-center">
				              <div className="slide-item-info">
				                <div className="slide-item-info-inner ltn__slide-animation">
				                  <div className="slide-video mb-50 d-none">
				                    <a className="ltn__video-icon-2 ltn__video-icon-2-border" href="https://www.youtube.com/embed/U5x36tiOPcc&t" data-rel="lightcase:myCollection">
				                      <i className="fa fa-play" />
				                    </a>
				                  </div>
				                  <h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-home" /></span> La Boutique de Inmuebles</h6>
				                  <h1 className="slide-title animated ">Encuentra la casa<br /> de tus sueños</h1>
				                  <div className="slide-brief animated">
				                    <p>En la Boutique de Inmuebles contámos con un amplio cátalogo de inmuebles listos para habitar, ¡contactános para realizar un tour virtual!</p>
				                  </div>
				                  <div className="btn-wrapper animated ">
				                    	<Link to="/propiedades" className="theme-btn-1 btn btn-effect-1 go-top">Ver inmuebles</Link>
				                    <a className="ltn__video-play-btn bg-white" href="https://www.youtube.com/embed/U5x36tiOPcc?autoplay=1&showinfo=0" data-rel="lightcase">
				                      <i className="icon-play  ltn__secondary-color" />
				                    </a>
				                  </div>
				                </div>
				              </div>
				              <div className="slide-item-img"> 
				                <img src={publicUrl+"assets/img/slider/21.png"} alt="Inmuebles" />
				              </div>
				            </div>
				          </div>
				        </div>
				      </div>
				    </div>
				    {/* ltn__slide-item */}
				    <div className="ltn__slide-item ltn__slide-item-2  ltn__slide-item-3-normal ltn__slide-item-3">
				      <div className="ltn__slide-item-inner  text-right text-end">
				        <div className="container">
				          <div className="row">
				            <div className="col-lg-12 align-self-center">
				              <div className="slide-item-info">
				                <div className="slide-item-info-inner ltn__slide-animation">
				                  <h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-home" /></span> La Boutique de Inmuebles</h6>
				                  <h1 className="slide-title animated ">¿No puedes vender  <br />tu inmueble?</h1>
				                  <div className="slide-brief animated">
				                    <p>
									En la botique de inmuebles te acompañamos un equipo de expertos desde inicio a fin del proceso. ¡Contamos con soluciones inteligentes a tu medida!
									</p>
				                  </div>
				                  <div className="btn-wrapper animated">
				                    <Link to="/servicios" className="theme-btn-1 btn btn-effect-1">Conoce nuestros servicios</Link>
				                  </div>
				                </div>
				              </div>
				              <div className="slide-item-img slide-img-left">
				                <img src={publicUrl+"assets/img/slider/22.png"} alt="Servicios" />
				              </div>
				            </div>
				          </div>
				        </div>
				      </div>
				    </div>
				    {/*  */}
				  </div>
			</div>
	)
}
