import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../blog-components/sidebar';
import NavBar from '../global-components/navbar';
import PageHeader from '../global-components/page-header';
import Footer from '../global-components/footer';

export default function ArticuloHogar() {

    let publicUrl = process.env.PUBLIC_URL+'/';

    return(
		<>
		<NavBar></NavBar>

		<div className={"ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image "} data-bs-bg={publicUrl+"assets/img/blog/1.jpg"}>
			<div className="container">
				<div className="row">
				<div className="col-lg-12">
					<div className="ltn__breadcrumb-inner">
					<h1 className="page-title">La BouTips</h1>
					<div className="ltn__breadcrumb-list">
						<ul>
						<li><Link to="/"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Inicio</Link></li>
						<li></li>
						</ul>
					</div>
					</div>
				</div>
				</div>
			</div>
		</div>

        <div className="ltn__page-details-area ltn__blog-details-area mb-120">
        <div className="container">
        <div className="row">
            <div className="col-lg-8">
            <div className="ltn__blog-details-wrap">
                <div className="ltn__page-details-inner ltn__blog-details-inner">
                <div className="ltn__blog-meta">
                    <ul>
                    <li className="ltn__blog-category">
                        <Link to="/blog-grid">LaBouTips</Link>
                    </li>
                    </ul>
                </div>
					<h2 className="ltn__blog-title">
                        Un hogar, una serie de espacios que por si mismos cuentan una historia.
					</h2>
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-author go-top">
							<i className="far fa-user" />Por: JCR - Two Arquitectura
						</li>
						<li className="ltn__blog-date">
							<i className="far fa-calendar-alt" />Abril 24, 2022
						</li>
						</ul>
					</div>
					<p>
                    </p>


					<img className="alignleft" src={publicUrl+"assets/img/blog/blog-details/1.jpg"} alt="Image1" />
					<p>
                        Un hogar, más allá de ser un refugio, un techo, el lugar donde habitamos... Desde mi punto de vista es una serie de espacios que por si mismos cuentan una historia. Tal como un libro nos permite enterarnos de viajes maravillosos e historias fantásticas. Los espacios de un hogar son justamente eso: Un lienzo en blanco sobre el cual se van tejiendo momentos, ilusiones, alegrías, etc. Que nos permiten conocer a las personas que moran en él, que imprimen sus experiencias, vivencias y linaje al mismo. Un hogar pues no es un espacio que se llena con objetos de una tienda o una página web. 
                    </p>


					<blockquote>
                        El hogar al igual que yo, es un ser vivo que evoluciona conmigo.
					</blockquote>

					<p>
                    El hogar al igual que yo, es un ser vivo que evoluciona conmigo. Que muestra mi riqueza interior, que da fe de mi crecimiento económico, del cuidado con el cual trato a las personas (manifestado con el trato que doy a los objetos), las memorias y recuerdos de cada día. La elección de colores en las paredes que va más allá de una moda, la comodidad de los muebles que sea más importante que una foto para Instagram. Es algo muy complicado el elegir la cama, pues debe darme confort y descanso, permitir el sueño reparador; la ropa de cama también aporta mucho a la identidad del hogar a través de sus colores, texturas y apariencia.
                    </p>

					<h2>Cabinet de Curiosité</h2>
					<p>
                    Una imagen salta a mi mente para mostrar lo que intento expresar. El Cabinet de Curiosité nos muestra las zonas y el conocimiento adquirido por los exploradores o investigadores de los siglos pasados. Botánica, zoología, geología, etc. estaban presentes en estos espacios. Quizá en esas épocas aún había mucho por descubrir.
                    </p>
					<img src={publicUrl+"assets/img/blog/35.jpg"} alt="2" />
					<label>Cabinet de Curiosité</label>

					<hr />


					<p>
                        Hoy ante la tecnología omnipresente, mi refugio son piezas que adquieren el cariño, atención y energía de sus creadores. Piezas que generalmente son únicas y muestran la riqueza de la cultura que sigue presente y persiste en estos momentos. Elegir lo mejor de esto, es mucho más que dar click o scroll down de una página.                     
                    </p>



					<h4>
                        Todo lo anterior me habla de un proceso de hacer sentir el hogar, de vivenciar y experimentar lo que me representa; lo que integra mi hogar.                    
                    </h4>

					<div className="row">
						<div className="col-lg-6">
						<img src={publicUrl+"assets/img/service/31.jpg"} alt="3" />
						<label>TWOarquitectura</label>
						</div>
						<div className="col-lg-6">
						<img src={publicUrl+"assets/img/service/32.jpg"} alt="4" />
						</div>
					</div>


					</div>
					{/* blog-tags-social-media */}
					<div className="ltn__blog-tags-social-media mt-80 row">
					<div className="ltn__tagcloud-widget col-lg-8 go-top">
						<h4>Autor: JCR - TWOarquitectura  </h4>
						<ul>
						</ul>
					</div>
					</div>
					<hr />

					<hr />

				</div>
				</div>
				<Sidebar/>
			</div>
			</div>
		</div>
		<Footer></Footer>
		</>
    )
  }
