import React, { useEffect, useContext, useReducer } from 'react';
import reducer from '../reducers/filter_reducer';
import {
  LOAD_PRODUCTS,
  UPDATE_SORT,
  SORT_PRODUCTS,
  FILTER_PRODUCTS,
  UPDATE_FILTERS,
  CLEAR_FILTERS,
  UPDATE_ALL_FILTERS
} from '../actions'
import { usePropertiesContext } from './properties-context';

const initialState = {
  filtered_properties: [],
  all_properties: [],
  sort: {
    select: "Todas",
    checkVenta: false,
    checkRenta: false,
    numRecamaras: 'Todas',
    precio: 100000000,
    minPrice: 0, 
    maxPrice: 100000000,
    tipoPropiedad: 'all',
    searchAddress: {}
  },
  filters: {
    text: '',
  },
  listadoTiposPropiedad: []
}

const FilterContext = React.createContext()

export const FilterProvider = ({ children }) => {
  const { properties } = usePropertiesContext()
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    dispatch({ type: LOAD_PRODUCTS, payload: properties })
  }, [properties])

  useEffect(() => {
    dispatch({ type: FILTER_PRODUCTS })
    dispatch({ type: SORT_PRODUCTS })
  }, [properties, state.sort, state.filters])

  const actualizaSort = (event, sort) => {
    const name = event.target.name;
    const value = event.target.value;

    //dispatch({ type: UPDATE_SORT, payload: value })
    dispatch({ type: UPDATE_SORT, payload: { sort: value, checkVenta: sort.checkVenta, checkRenta: sort.checkRenta } })
  }

  const updateFilters = (e) => {
    let name = e.target.name
    let value = e.target.value
    dispatch({ type: UPDATE_FILTERS, payload: { name, value } })
  }

  const clearFilters = () => {
    dispatch({ type: CLEAR_FILTERS })
  }

  const actualizarFiltros = (value, filtro) => {
    let currentValue = null;
    console.log(value);

    if (filtro === 'precio' || filtro === 'numRecamaras' || filtro === 'tipoPropiedad' || filtro == 'select') {
      currentValue = value.target.value;
    } else if (filtro == 'checkVenta' || filtro == 'checkRenta') {
      currentValue = value.target.checked;
    }else if(filtro === 'rangePrice'){
      currentValue = value;
    }else if(filtro == 'searchAddress' && value.target.value.length > 3){
      currentValue = value.target.value;
    }

    dispatch({ type: UPDATE_ALL_FILTERS, payload: { currentValue, filtro } })
  }

  return (
    <FilterContext.Provider
      value={{
        ...state,
        actualizaSort,
        updateFilters,
        clearFilters,
        actualizarFiltros
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}


export const useFilterContext = () => {
  return useContext(FilterContext)
}

