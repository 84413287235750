import React from 'react';
import { useFilterContext } from '../../context/filter-context';
// import '../../assets/css/select-range.css'
import { SORT_PRODUCTS } from '../../actions';
import MultiRangeSlider from '../multi-range-slider/multi-range-slider';

const Sort = () => {
    const {
        filtered_properties: properties,
        sort,
        actualizaSort,
        actualizarFiltros,
        listadoTiposPropiedad
    } = useFilterContext()

    const generarOptionsGeneric = (kind) => {
        let listadoInicial = ['Todas'];

        if (kind === 'recamaras') {
            for (let i = 1; i <= 9; i++) {
                listadoInicial.push(i)
            }

            return listadoInicial.map(itemMap => (
                <option value={itemMap} key={itemMap}>{itemMap}</option>
            ))
        } else if (kind === 'tipoPropiedad') {
            if (listadoTiposPropiedad && listadoTiposPropiedad.length > 0) {
                listadoInicial = [{ id: 0, code: 'all', name: 'Todas' }, ...listadoTiposPropiedad];
                return listadoInicial.map(itemMap => (
                    <option value={itemMap.code} key={itemMap.code}>{itemMap.name}</option>
                ));
            }
        }

        
    }

    return (
    <>
        <div className="ltn__shop-options">
            <ul className="justify-content-between">
                <li>
                    <div className="ltn__grid-list-tab-menu ">
                        <div className="nav">
                            <a className="active show" href="#" data-bs-toggle="tab"><i className="fas fa-list" data-bs-toggle="tab" /></a>
                            <span>{properties.length} Propiedades Encontradas</span>
                        </div>
                    </div>
                </li>
                
                <li>
                <form>
                    <div className="wrapper">
                        <select
                            name='sort'
                            id='sort'
                            className="select"
                            value={sort.select}
                            onChange={(e) => actualizarFiltros(e, 'select')}                                >
                            <option value='Todas'>Ordenar:</option>
                            <option value='masrecientes'>Más Recientes</option>
                            <option value='menosrecientes'>Menos Recientes</option>
                            <option value='filtro1'>Menor Precio de Venta</option>
                            <option value='filtro2'>Mayor Precio de Venta</option>
                        </select>
                    </div>
                </form>
                </li>
            </ul>
        </div>

        <div className="container">
            <div className="ltn__faq-inner ltn__faq-inner-2">
            {/* <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                <input type="text" className="unitRight size1of2 talign-right" style={{margin: '0px'}} onChange={(e) => actualizarFiltros(e, 'searchAddress')}/>
                <button className='theme-btn-1 btn' style={{height: '65px'}}>Buscar</button>
                </div>
            */}
            <div id="accordion_2">
                {/* card */}
                <div className="card">
                    <h1 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="true">
                        Filtrar:
                    </h1>
                    <div id="faq-item-2-1" className="collapse show" data-bs-parent="#accordion_2">
                        <div className="card-body">

                            
                            <div className='row'>

                                {/* Estatus */}            
                                <div className='col-8 col-sm-8 col-md-4 col-lg-4'>
                                    <div style={{ width: '100%' }}>
                                        <span className='span-title'>Estatus</span>
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <ul style={{ listStyleType: "none", display: 'flex', justifyContent: 'flex-start', padding: '0px' }}>
                                        <li>
                                            <label className="checkbox-item">Venta
                                                <input id="ventaID" type="checkbox" onChange={(e) => actualizarFiltros(e, 'checkVenta')} name="tipoOperacion"  value={sort.checkVenta} />
                                                <span className="checkmark" />
                                            </label>
                                        </li>
                                            <li style={{ marginLeft: '20px' }}>
                                                <label className="checkbox-item">Renta
                                                    <input id="rentaID" type="checkbox" onChange={(e) => actualizarFiltros(e, 'checkRenta')} name="tipoOperacion" value={sort.checkVenta} />
                                                    <span className="checkmark" />
                                                </label>
                                                {/* <span className="categorey-no">(10)</span> */}
                                            </li>
                                        </ul>
                                    </div>
                                </div>


                                {/* Tipo de Propiedad */}            
                                <div className='col-8 col-sm-8 col-md-4 col-lg-4'>
                                    <div style={{ width: '100%' }}>
                                        <span className='span-title'>Tipo de Propiedad</span>
                                    </div>
                                <div style={{ width: '100%' }}>
                                    <div className="wrapper">
                                        <select
                                            name='tipoPropiedadID'
                                            id='tipoPropiedadID'
                                            className="select"
                                            style={{ marginTop: '16px', minWidth: '70%' }}
                                            value={sort.tipoPropiedad}
                                            onChange={(e) => actualizarFiltros(e, 'tipoPropiedad')}
                                        >
                                            {generarOptionsGeneric('tipoPropiedad')}
                                        </select>
                                    </div>
                                </div>

                                </div>


                                {/* Récamaras           
                                <div className='col-8 col-sm-8 col-md-4 col-lg-4'>
                                    <div style={{ width: '100%' }}>
                                        <span className='span-title'>Recamaras</span>
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <div className="wrapper">
                                            <select
                                                name='recamarasSelectID'
                                                id='recamarasSelectID'
                                                className="select"
                                                style={{ marginTop: '16px', minWidth: '70%' }}
                                                value={sort.numRecamaras}
                                                onChange={(e) => actualizarFiltros(e, 'numRecamaras')}
                                            >
                                                {generarOptionsGeneric('recamaras')}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className='row second-row-filter'>
                                {/* Precio             
                                <div className='col-8 col-sm-8 col-md- col-lg-4'>
                                    <div style={{ width: '100%' }}>
                                        <span className='span-title'>Precio</span>
                                    </div>
                                    <div style={{ width: '100%' }} className="slider-potition-align">
                                        <MultiRangeSlider
                                            min={0}
                                            max={sort.precio ? sort.precio : 100000000}
                                            onChange={({ min, max }) => actualizarFiltros({ minPrice: min, maxPrice: max }, 'rangePrice')}
                                        />
                                    </div>
                                </div>
                                */}




                            </div>


                            <div className='justify-content-end' style={{ width: '100%', display: 'flex' }}>
                                <button type='button' className='clear-btn' onClick={(e) => actualizarFiltros(e, 'eliminarFiltros')} >
                                    Borrar Filtros
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>
)}

export default Sort




