import React from 'react';
import { Link } from 'react-router-dom';
import Sort from './sort';
import Filters from './filters';
import FiltrosAcordeon from './property-filterbar';


const ShopGridV1 = ({ properties }) => {

	return (
		<div>
			<div className="ltn__product-area ltn__product-gutter">
				<div className="container">
					<div className="row">
						{/* <FiltrosAcordeon /> */}
						<div className="col-lg-12 order-lg-2 mb-100">
							<div className="tab-content">
								<div className="tab-pane fade active " id="liton_product_list">
									<div className="ltn__product-tab-content-inner ltn__product-list-view">
										<div className="row">
											<Sort />
											{/* <Filters /> */}
											{/* ltn__product-item */}
											{properties.map(property => (
												<Propiedades key={property.id} propiedad={property} />
											))}
											{/*  */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ShopGridV1


function Propiedades(props) {
	const tipoOperacion = props.propiedad.operations[0].operation_type;
	const montoOperacion = props.propiedad.operations[0].prices[0].price;
	const titulo = props.propiedad.publication_title;
	const slug = titulo.replace(/\s+/g, '-');

	const findAndCovertPriceByType = (props) => {
		let findPrice = null;
		if(props !== null && props !== undefined && props !== ''){
			props.prices.forEach(item => {
				if(item.currency === 'USD'){
					findPrice = (item.price)
				}else {
					findPrice = item.price;
				}
			})
		}
		return findPrice;
	}

	const generateOptionsOfStatus = () => {
		if (props != null && props != undefined && props != '') {
			return props.propiedad.operations.map(itemMap => (		
				<div className="product-price" key={itemMap.operation_type}>
					<span>${findAndCovertPriceByType(itemMap).toLocaleString("es-MX")} {props.propiedad.operations[0].prices[0].currency} <label>/{itemMap.operation_type}</label></span>
				</div>
			))
		}
	}

	return (
		<div className="col-lg-12">
			<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
				<div className="product-img go-top">
					<Link to={`/p/${props.propiedad.id}-${slug}`}><img src={props.propiedad.photos[0].image} alt={props.propiedad.address} /></Link>
				</div>
				<div className="product-info">
					<div style={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
						{/* <div className="product-badge">
							<ul>
								<li className="sale-badg">{props.propiedad.operations.operation_type}</li>
							</ul>
						</div> */}

						{/* <div className="product-price">
								<span>${montoOperacion.toLocaleString("es-MX")} {props.propiedad.operations[0].prices[0].currency} <label>/{tipoOperacion}</label></span>
							</div> */}
						{generateOptionsOfStatus()}

					</div>
					<h2 className="product-title go-top"><Link to={`/p/${props.propiedad.id}-${slug}`}>{props.propiedad.publication_title}</Link></h2>
					<div className="product-img-location go-top">
						<ul>
							<li>
								<Link to={`/p/${props.propiedad.id}-${slug}`}><i className="flaticon-pin" />{props.propiedad.location.short_location}</Link>
							</li>
						</ul>
					</div>

					<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
						{props.propiedad.suite_amount!=0 && <li><span>{props.propiedad.suite_amount} </span>Recámaras</li>}
						{props.propiedad.bathroom_amount!=0 && <li><span>{props.propiedad.bathroom_amount} </span>Baños</li>}
						{props.propiedad.toilet_amount!=0 && <li><span>{props.propiedad.toilet_amount} </span>Medios Baño(s)</li>}
						{props.propiedad.parking_lot_amount!=0 && <li><span>{props.propiedad.parking_lot_amount} </span>Lugar(es) de Estacionamiento</li>}
						{props.propiedad.surface!=0 && <li><span>Terreno: {props.propiedad.surface} </span>mts2</li>}
						{props.propiedad.total_surface!=0 && <li><span>Sup. Construcción: {props.propiedad.total_surface} </span>mts2</li>}
					</ul>
				</div>

				<div className="product-info-bottom">
					<div className="real-estate-agent">
						<div className="agent-img">
							<img src={props.propiedad.producer.picture} alt={props.propiedad.id} />
						</div>
						<div className="agent-brief">
							<h6>{props.propiedad.producer.name}</h6>
							<small>La Boutique de Inmuebles</small>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}