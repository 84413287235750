import React from 'react'; 
import useFetch from '../hooks/useFetch';
import Navbar from './global-components/navbar';
import Banner from './section-components/banner';
import AboutV2 from './section-components/about-v2';
import Featuresv1 from './section-components/features-v1';
import ProSlider from './section-components/property-slider';
import Testimonial from './section-components/testimonial-v1';
import BlogSlider from './blog-components/blog-slider-v1';
import Footer from './global-components/footer';
import {URL_API, API} from '../utils/constants';

export default function Home () {
    const destacadas = useFetch(`${URL_API}/property/search?lang=es_ar&format=json&limit=20&offset=0&order=ASC&data=%7B+%22current_localization_id%22%3A14%2C+%22current_localization_type%22%3A%22country%22%2C+%22price_from%22%3A0%2C+%22price_to%22%3A9999999999%2C+%22operation_types%22%3A%5B%5D%2C+%22property_types%22%3A%5B3%5D%2C+%22currency%22%3A%22ANY%22%2C+%22with_custom_tags%22%3A+%5B20579%5D%7D&key=${API}`);
 
    if(destacadas.loading || !destacadas.result){
		return <div className="cv-spinner"><span className="spinner"></span>
    </div>
    } 
    
    return( 
        <>
            <Navbar />
            <Banner />
            <Featuresv1 customClass="ltn__feature-area section-bg-8 pt-80 pb-50 mb-120---"/>
            <AboutV2  principal =  {destacadas} />
            <ProSlider principal = {destacadas}/>
            <BlogSlider customClass="section-subtitle-2"/>
            <Testimonial />
            <Footer />
        </>
    )
}
