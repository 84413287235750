import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch } from "react-router-dom";
import { PropertiesProvider } from './context/properties-context';
import { FilterProvider } from './context/filter-context';
import Home from './components/home';
import Services from './components/services';
import Error  from './components/404';
import PropertyDetails from './components/property-details';
import Properties from './components/properties';
import BlogRightSidebar from './components/blog-grid';
import EspaciosCuentanHistoria from './components/articles/hogar-espacios-cuentan-historia';
import AmbientesLuz from './components/articles/ambientes-creados-con-luz';
import CreditoHipotecario from './components/articles/conceptos-debes-saber-credito';
import VivirInteriorismo from './components/articles/vivir-interiorismo-nuevas-tendencias';
import LuzFelizCasa from './components/articles/como-ser-mas-feliz-luz';


import Contact from './components/contact';

const App =()=>{
    return(
        <HashRouter>   
            <Switch>	                    
                <Route path="/servicios" component={ Services } /> 
                <Route path="/propiedades" component={ Properties } />
                <Route path="/p/:id" exact={true} component={ PropertyDetails } />
                <Route path="/blog-grid" component={ BlogRightSidebar } />
                <Route path="/blog/que-significa-para-mi-un-hogar" component={ EspaciosCuentanHistoria } />
                <Route path="/blog/ambientes-creados-con-luz" component={ AmbientesLuz } />
                <Route path="/blog/conceptos-debes-saber-credito" component={ CreditoHipotecario } />
                <Route path="/blog/vivir-interiorismo-nuevas-tendencias" component={ VivirInteriorismo } />
                <Route path="/blog/como-ser-mas-feliz-luz" component={ LuzFelizCasa } />


                <Route path="/contacto" component={ Contact } />
                <Route path="/" exact={true} component={ Home } />
                <Route path="/*" component={ Error } />
            </Switch>
        </HashRouter> 
    )
}

export default App;
ReactDOM.render(<PropertiesProvider><FilterProvider><App /></FilterProvider></PropertiesProvider>, document.getElementById('quarter'));
