import React from 'react';
import { Link } from 'react-router-dom';

export default function PropertySlider(props) {
	const { principal } = props;
  const favoritos  = principal.result.objects;
    return ( 
        <>  
        <div>
            <div className="ltn__product-slider-area ltn__product-gutter pt-15 pb-35 plr--7">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title-area ltn__section-title-2--- text-center">
                      <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                        Inmuebles
                      </h6>
                      <h2 className="section-title">¿Quiéres conocer un inmueble?, contáctanos para brindarte asesoria personalizada</h2>
                      <Link to="/propiedades" className="theme-btn-1 btn btn-effect-1 go-top">Ver inmuebles</Link>

                    </div>
                  </div>
                </div>
                                
                <div className="row ltn__product-slider-item-four-active-full-width slick-arrow-1">
                    {favoritos.map(favorito=>(
                        <Propiedades key={favorito.id} propiedad = {favorito} />
                    ))}                      
        				</div>     
              </div>
            </div>
          </div>
        </>
    )
}


function Propiedades(props){
    const tipoOperacion = props.propiedad.operations[0].operation_type;
    const montoOperacion = props.propiedad.operations[0].prices[0].price;
    const titulo = props.propiedad.publication_title;
    const slug = titulo.replace(/\s+/g, '-');
    return (
      <>
      <div className="col-lg-12">
      <div className="ltn__product-item ltn__product-item-4 text-center---">
        <div className="product-img go-top">
        <Link to={`/p/${props.propiedad.id}-${slug}`}><img src={props.propiedad.photos[0].image} alt={props.propiedad.address} /></Link>
        <div className="product-badge">
          <ul>
          <li className="sale-badge bg-green">{tipoOperacion}</li>
          </ul>
        </div>
        </div>
        <div className="product-info">
        <div className="product-price">
          <span>${montoOperacion.toLocaleString("es-MX")} {props.propiedad.operations[0].prices[0].currency}<label> / {tipoOperacion}</label></span>
        </div>
        <h2 className="product-title go-top"><Link to={`/p/${props.propiedad.id}-${slug}`}>{props.propiedad.address}</Link></h2>
        <div className="product-description">
          <p>{props.propiedad.location.short_location}</p>
        </div>
        <ul className="ltn__list-item-2 ltn__list-item-2-before">
          <li><span>{props.propiedad.suite_amount} <i className="flaticon-bed" /></span>Recámaras</li>
          <li><span>{props.propiedad.bathroom_amount} <i className="flaticon-clean" /></span>Baños</li>
          <li><span>{props.propiedad.parking_lot_amount} <i className="flaticon-car" /></span>Estacionamiento</li>
          <li><span>{Number(props.propiedad.total_surface)} <i className="flaticon-house-1" /></span>Construcción mts2</li>
          <li><span>{Number(props.propiedad.surface)} <i className="flaticon-square-shape-design-interface-tool-symbol" /></span>Terreno mts2</li>
        </ul>
        </div>
        <div className="product-info-bottom">
        <div className="real-estate-agent">
          <div className="agent-img go-top">
          <img src={props.propiedad.producer.picture} alt={props.propiedad.producer.picture} />
          </div>
          <div className="agent-brief go-top">
          <h6>{props.propiedad.producer.name}</h6>
          <small>La Boutique de Inmuebles</small>
          </div>
        </div>
        </div>
      </div>
      </div>
      </>
    ) 
}


