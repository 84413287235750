import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class BlogGrid extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'

	return (
		<div className="ltn__blog-area ltn__blog-item-3-normal mb-100 go-top">
			<div className="container">
			<div className="row">
				{/* Blog Item */}
				<div className="col-lg-4 col-sm-6 col-12">
				<div className="ltn__blog-item ltn__blog-item-3">
					<div className="ltn__blog-img">
					<Link to="/blog/que-significa-para-mi-un-hogar"><img src={publicUrl+"assets/img/blog/1.jpg"} alt="#" /></Link>
					</div>
					<div className="ltn__blog-brief">
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-author go-top">
							<i className="far fa-user" />JCR - Two Arquitectura
						</li>
						<li className="ltn__blog-tags go-top">
							<i className="fas fa-tags" />Inspiración
						</li>
						</ul>
					</div>
					<h3 className="ltn__blog-title">
						<Link to="/blog/que-significa-para-mi-un-hogar">
							¿Qué significa para mi un hogar?
						</Link>
					</h3>

					<div className="ltn__blog-meta-btn">
						<div className="ltn__blog-meta">
						<ul>
							<li className="ltn__blog-date"><i className="far fa-calendar-alt" />Abril 2022</li>
						</ul>
						</div>
						<div className="ltn__blog-btn">
						<Link to="/blog/que-significa-para-mi-un-hogar">Ver Artículo</Link>
						</div>
					</div>
					</div>
				</div>
				</div>


				{/* Blog Item */}
				<div className="col-lg-4 col-sm-6 col-12">
				<div className="ltn__blog-item ltn__blog-item-3">
					<div className="ltn__blog-img">
					<Link to="/blog/ambientes-creados-con-luz"><img src={publicUrl+"assets/img/blog/2.jpg"} alt="#" /></Link>
					</div>
					<div className="ltn__blog-brief">
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-author go-top">
							<i className="far fa-user" />JCR - Two Arquitectura
						</li>
						<li className="ltn__blog-tags go-top">
							<i className="fas fa-tags" />Inspiración
						</li>
						</ul>
					</div>
					<h3 className="ltn__blog-title">
						<Link to="/blog/ambientes-creados-con-luz">
							Ambientes creados con Luz, conceptos básicos
							<br></br>
							<br></br>
						</Link>
					</h3>

					<div className="ltn__blog-meta-btn">
						<div className="ltn__blog-meta">
						<ul>
							<li className="ltn__blog-date"><i className="far fa-calendar-alt" />Abril 2022</li>
						</ul>
						</div>
						<div className="ltn__blog-btn">
						<Link to="/blog/ambientes-creados-con-luz">Ver Artículo</Link>
						</div>
					</div>
					</div>
				</div>
				</div>


				{/* Blog Item */}
				<div className="col-lg-4 col-sm-6 col-12">
				<div className="ltn__blog-item ltn__blog-item-3">
					<div className="ltn__blog-img">
					<Link to="/blog/conceptos-debes-saber-credito"><img src={publicUrl+"assets/img/blog/3.jpg"} alt="#" /></Link>
					</div>
					<div className="ltn__blog-brief">
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-author go-top">
							<i className="far fa-user" />La Boutique de Inmuebles
						</li>
						<li className="ltn__blog-tags go-top">
							<i className="fas fa-tags" />Asesoria
						</li>
						</ul>
					</div>
					<h3 className="ltn__blog-title">
						<Link to="/blog/conceptos-debes-saber-credito">
							Créditos hipotecarios, ¿Qué debes tomar en cuenta? 
							<br></br>
							<br></br>
						</Link>
					</h3>

					<div className="ltn__blog-meta-btn">
						<div className="ltn__blog-meta">
						<ul>
							<li className="ltn__blog-date"><i className="far fa-calendar-alt" />Octubre 2022</li>
						</ul>
						</div>
						<div className="ltn__blog-btn">
						<Link to="/blog/conceptos-debes-saber-credito">Ver Artículo</Link>
						</div>
					</div>
					</div>
				</div>
				</div>


				{/* Blog Item */}
				<div className="col-lg-4 col-sm-6 col-12">
				<div className="ltn__blog-item ltn__blog-item-3">
					<div className="ltn__blog-img">
					<Link to="/blog/vivir-interiorismo-nuevas-tendencias"><img src={publicUrl+"assets/img/blog/vivirnuevastendencias.jpg"} alt="#" /></Link>
					</div>
					<div className="ltn__blog-brief">
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-author go-top">
							<i className="far fa-user" />Zdeyna Cortés
						</li>
						<li className="ltn__blog-tags go-top">
							<i className="fas fa-tags" />Diseñadora industrial y de interiores
						</li>
						</ul>
					</div>
					<h3 className="ltn__blog-title">
						<Link to="/blog/vivir-interiorismo-nuevas-tendencias">
						Vivir el interiorismo desde las nuevas tendencias.  
							<br></br>
							<br></br>
						</Link>
					</h3>

					<div className="ltn__blog-meta-btn">
						<div className="ltn__blog-meta">
						<ul>
							<li className="ltn__blog-date"><i className="far fa-calendar-alt" />Octubre 2022</li>
						</ul>
						</div>
						<div className="ltn__blog-btn">
						<Link to="/blog/vivir-interiorismo-nuevas-tendencias">Ver Artículo</Link>
						</div>
					</div>
					</div>
				</div>
				</div>



				{/* Blog Item */}
				<div className="col-lg-4 col-sm-6 col-12">
				<div className="ltn__blog-item ltn__blog-item-3">
					<div className="ltn__blog-img">
					<Link to="/blog/como-ser-mas-feliz-luz"><img src={publicUrl+"assets/img/blog/sermasfelizluz.jpg"} alt="#" /></Link>
					</div>
					<div className="ltn__blog-brief">
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-author go-top">
							<i className="far fa-user" />JCR - Two Arquitectura
						</li>
						<li className="ltn__blog-tags go-top">
							<i className="fas fa-tags" />Inspiración
						</li>
						</ul>
					</div>
					<h3 className="ltn__blog-title">
						<Link to="/blog/como-ser-mas-feliz-luz">
							¿Cómo la luz te permite ser más feliz en casa?
							<br></br>
							<br></br>
						</Link>
					</h3>

					<div className="ltn__blog-meta-btn">
						<div className="ltn__blog-meta">
						<ul>
							<li className="ltn__blog-date"><i className="far fa-calendar-alt" />Noviembre 2022</li>
						</ul>
						</div>
						<div className="ltn__blog-btn">
						<Link to="/blog/como-ser-mas-feliz-luz">Ver Artículo</Link>
						</div>
					</div>
					</div>
				</div>
				</div>



			</div>
			<div className="row">
				<div className="col-lg-12">

				</div>
			</div>
			</div>
		</div>
	  
    )
  }
}

export default BlogGrid;
