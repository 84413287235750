import React, { Component } from 'react';

class AboutV4 extends Component {

    render() {

    let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="ltn__about-us-area pt-120--- pb-90 mt--130 go-top">
				<div className="container">
				<div className="row">
					<div className="col-lg-6 align-self-center">
					<div className="about-us-img-wrap about-img-left">
						<img src={publicUrl+"assets/img/others/13.png"} alt="Acerca de Nosotros" />
						<div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
						</div>
					</div>
					</div>
					<div className="col-lg-6 align-self-center">
					<div className="about-us-info-wrap">
						<div className="section-title-area ltn__section-title-2--- mb-20">
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color"></h6>
						<h1 className="section-title">La Boutique de Inmuebles 
							<span>.</span></h1>
							<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">¿Quiénes somos?</h6>

						<p>Somos un equipo especialista en el ramo inmobiliario, contamos con más de 20 años
							de experiencia:
						</p>
						</div>
						<ul className="ltn__list-item-half clearfix">
						<li>
							<i className="flaticon-home-2" />
							Ayudandote a encontrar el inmueble que siempre haz soñado
						</li>
						<li>
							<i className="flaticon-mountain" />
							Asesorandote de manera personalizada en todo tu proceso
						</li>
						<li>
							<i className="flaticon-heart" />
							Innovando en generar los mejores servicios inmobiliarios
						</li>
						<li>
							<i className="flaticon-secure" />
							Brindandote la seguridad y garantizando tu inversión
						</li>
						</ul>
						<div className="ltn__callout bg-overlay-theme-05  mt-30">
						<p>"Brindamos soluciones inmobiliarias personalizadas, contamos con un equipo especialista en las áreas legal, inmobiliaria, financiera y fiscal para garantizar tu tranquilidad en esa experiencia que sabemos es muy importante para ti! "- Marco Valdes (Director Comercial La Boutique de Inmuebles) 
							
						</p>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
        }
}

export default AboutV4