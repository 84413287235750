import React from 'react';
import Navbar from './global-components/navbar';
import Faq from './section-components/faq-v1';
import AboutV4 from './section-components/about-v4';
import UpComingProduct from './section-components/upcoming-product-v1';

import Footer from './global-components/footer';

const Services = () => {
    return <div>
        <Navbar />
        <AboutV4 />
        <UpComingProduct />
        <Faq />
        <Footer />
    </div>
}

export default Services

