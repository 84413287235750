import React, { useContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import reducer from '../reducers/products_reducer';
import { products_url as url } from '../utils/constants';
import {
  GET_PRODUCTS_BEGIN,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR
} from '../actions'

const initialState = {
  properties_loading: false,
  properties_error: false,
  properties: [],
  featured_properties: [],
  dolar: 20.85,
  listadoTiposPropiedad: []
}

const PropertiesContext = React.createContext()

export const PropertiesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const fetchProperties = async (url) => {
    dispatch({ type: GET_PRODUCTS_BEGIN })
    try {
      const response = await axios.get(url);
      let properties = response.data.objects;
    
      console.log(properties);

      //PROCESAR PRICES
      properties = properties.map(function (properties) {
        properties.operations.map(function (operation) {
          return operation.prices.map(function (prices) {

            properties["final_price_venta"] = 0;
            properties["final_price_renta"] = 0;

            //CONVERETE EL PESO A DOLLAR 
            /*if (prices.currency.toLocaleLowerCase() === 'usd') { prices.price = prices.price * state.dolar }*/

            //INSERTA LA PROPIEDAD A NIVEL RAIZ SI ES VENTA O RENTA 
            if (operation.operation_type.toLocaleLowerCase() === 'venta') {
              properties["final_price_venta"] = prices.price

            } else if (operation.operation_type.toLocaleLowerCase() === 'alquiler') {

              properties["final_price_renta"] = prices.price
            }
          })
        })

        return properties;
      })
      // 

      dispatch({ type: GET_PRODUCTS_SUCCESS, payload: properties })
    } catch (error) {
      dispatch({ type: GET_PRODUCTS_ERROR })
    }
  }

  useEffect(() => {
    fetchProperties(url)
  }, [])

  return (
    <PropertiesContext.Provider
      value={{ ...state }}
    >
      {children}
    </PropertiesContext.Provider>
  )
}
// make sure use
export const usePropertiesContext = () => {
  return useContext(PropertiesContext)
}