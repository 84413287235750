import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../blog-components/sidebar';
import NavBar from '../global-components/navbar';
import PageHeader from '../global-components/page-header';
import Footer from '../global-components/footer';

export default function ArticuloHogar() {

    let publicUrl = process.env.PUBLIC_URL+'/';

    return(
		<>
		<NavBar></NavBar>

		<div className={"ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image "} data-bs-bg={publicUrl+"assets/img/blog/1.jpg"}>
			<div className="container">
				<div className="row">
				<div className="col-lg-12">
					<div className="ltn__breadcrumb-inner">
					<h1 className="page-title">La BouTips</h1>
					<div className="ltn__breadcrumb-list">
						<ul>
						<li><Link to="/"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Inicio</Link></li>
						<li></li>
						</ul>
					</div>
					</div>
				</div>
				</div>
			</div>
		</div>

        <div className="ltn__page-details-area ltn__blog-details-area mb-120">
        <div className="container">
        <div className="row">
            <div className="col-lg-8">
            <div className="ltn__blog-details-wrap">
                <div className="ltn__page-details-inner ltn__blog-details-inner">
                <div className="ltn__blog-meta">
                    <ul>
                    <li className="ltn__blog-category">
                        <Link to="/blog-grid">LaBouTips</Link>
                    </li>
                    </ul>
                </div>
					<h2 className="ltn__blog-title">
						Vivir el interiorismo desde las nuevas tendencias. (Parte I)
					</h2>
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-author go-top">
							<i className="far fa-user" />Por: Zdeyna Cortés - Diseñadora industrial y de interiores
						</li>
						<li className="ltn__blog-date">
							<i className="far fa-calendar-alt" />Octubre, 2022
						</li>
						</ul>
					</div>
					<p>
                    </p>


					<img className="alignleft" src={publicUrl+"assets/img/blog/nuevastendencias/colorimetria1.jpg"} alt="Image1" />
					<p>
						Es real que hay un before and after tras vivir varios confinamientos, 
						reconsiderando la importancia que refleja nuestro día a día para mejorar 
						nuestro estilo de vida, y así cumplir con las necesidades
						reflejada en gustos. 
						
						<br />
						<br />

						Hemos experimentado reformas en decoración y modo de vida, acentuados por la 
						pandemia. También redefinimos las características con las que cumplen nuestras 
						expectativas al comprar una vivienda, un lugar en donde vivir y pasar más tiempo; 
						encontrar un espacio que aporte equilibrio y se adapte a nuestro estilo de vida.                     
					</p>



					<blockquote>
						La decoración y el diseño de los hogares juega un papel fundamental; 
						enfocándose en colores, muebles, formas, texturas y accesorios para crear 
						un ambiente perfecto que ayude a lograr nuestros objetivos. 					
					</blockquote>

					<p>
						Es por eso que te comparto varias claves para estar en un espacio cómodo, armonioso, 
						eficiente y en moda con las tendencias que nos marcarán el 2023. 
					</p>

					<h6>Colorimetría que aporte y sea conductor emocional y psicológicamente.</h6>
					<img src={publicUrl+"assets/img/blog/nuevastendencias/colorimetria2.jpg"} alt="colorimetria" />
					<h6>Líneas curvas y orgánicas que reflejen las formas que la naturaleza nos provee.</h6>
					<img src={publicUrl+"assets/img/blog/nuevastendencias/formasredondas.jpg"} alt="formas" />
					<h6>Integrar naturaleza a espacios de interior y materiales orgánicos.</h6>
					<img src={publicUrl+"assets/img/blog/nuevastendencias/plantas.jpg"} alt="naturaleza" />
					<h6>Jugar con los accesorios en diversos espacios, generando diferentes ambientes según la estación.</h6>
					<img src={publicUrl+"assets/img/blog/nuevastendencias/articulos.jpg"} alt="articulos" />


					<br/>

					<blockquote>
					Recuerda que un entorno orientado a facilitar una vida saludable puede marcar gran diferencia en 
					nuestra rutina y así reducir la tensión del agitado ritmo de la ciudad. 
					Practicar mindfulness en compañía de la naturaleza, luz natural, tonos cálidos e integrar
					soluciones que contribuyan al rendimiento energético y ayuden a reducir el impacto ambiental; 
					pueden generar dentro de nosotros y de nuestros ambientes a transmitir una imagen y sensación de 
					comodidad y comunidad.                     
					</blockquote>

					<hr />

					<p>
					¡Te invitamos a que conozcas más de esas tendencias en los próximos post y nos sigas en redes!

					</p>


					</div>


				</div>
				</div>
				<div className="col-lg-4 go-top">
			<aside className="sidebar-area blog-sidebar ltn__right-sidebar">
				{/* Author Widget */}
				<div className="widget ltn__author-widget">
				<div className="ltn__author-widget-inner text-center">
					<img src={publicUrl+"assets/img/team/logotipo.jpeg"} alt="Image" />
					<h5>Zdeyna Cortés</h5>
					<small>Diseñadora industrial y de interiores</small>
					<br></br>
					<small>CZ Interior Holístico</small>

					<div className="product-ratting">
					<ul>
						<li><a href="#"><i className="fas fa-star" /></a></li>
						<li><a href="#"><i className="fas fa-star" /></a></li>
						<li><a href="#"><i className="fas fa-star" /></a></li>
						<li><a href="#"><i className="fas fa-star" /></a></li>
						<li><a href="#"><i className="fas fa-star" /></a></li>

					</ul>
					</div>
					<p>
					 Interior holístico se enfoca en ver las cosas enteras en su totalidad, conjunto y complejidad; contribuyendo a la salud física, psicológica y espiritual 
					</p>
					<div className="ltn__social-media">
					<ul>
						<li><a href="https://www.facebook.com/profile.php?id=100066722270888" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
					</ul>
					</div>
				</div>
				</div>
				{/* Tagcloud Widget */}
				<div className="widget ltn__tagcloud-widget go-top">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Secciones</h4>
				<ul>
					<li><Link to="/propiedades">Ver todos los inmuebles</Link></li>
					<li><Link to="/servicios">Nuestros Servicios</Link></li>
					<li><Link to="/contacto">Contáctanos</Link></li>
				</ul>
				</div>
			</aside>
		</div>

			</div>
			</div>
		</div>
		<Footer></Footer>
		</>
    )
  }
