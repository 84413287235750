import React from 'react';
import { Link } from 'react-router-dom';

export default function FaqV1 () {
    let publicUrl = process.env.PUBLIC_URL+'/'

    return (
        <div className="ltn__faq-area mb-100">
            <div className="container">
            <div className="row">
                <div className="col-lg-8">
                <div className="ltn__faq-inner ltn__faq-inner-2">
                    <div id="accordion_2">
                        {/* card */}
                        <div className="card">
                            <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="false">
                                ¿Qué es una estrategía MKT 360?
                            </h6>
                            <div id="faq-item-2-1" className="collapse" data-bs-parent="#accordion_2">
                            <div className="card-body">
                                <p>
                                    Realizamos una estrategia profesional y personalizada para definir la mejor manera de realizar la publicidad de la propiedad. 
                                    Nuestra estrategía de publicidad consta de:
                                    <br></br>        
                                    <br></br>        
                                    1) Estudio fotográfico para publicar más de 24 fotos de cada inmueble.
                                    <br></br>        
                                    2) Diseño del rótulo personalizado dependiendo el tamaño ideal para colocar en el inmueble, este incluye código QR que lleva a los compradores directamente a la ficha técnica de la propiedad. 
                                    <br></br>        
                                    3) Publicación en los mejores portales inmobiliarios digitales como son Inmuebles24, Lamudi, Propiedades.com, Vivanuncios, laboutiquedeinmuebles.com y otros más...
                                    <br></br>        
                                    4) Contamos con nuestro propio CRM para darle seguimiento a los clientes, así como unificar la información de las propiedades para que se publique en todos los portales de forma idéntica. 
                                    <br></br>        
                                    5) Publicamos las propiedades en nuestras redes sociales: FB, IG y LindedIn.
                                </p>
                            </div>
                            </div>
                        </div>
                        {/* card */}


                        <div className="card">
                        <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-2" aria-expanded="false">
                            ¿Pueden ayudarme administrar mi inmueble sino vivo en la ciudad?
                            </h6>
                            <div id="faq-item-2-2" className="collapse" data-bs-parent="#accordion_2">
                            <div className="card-body">
                                <p>
                                    Si, en caso de que te encuentres fuera de la ciudad ó en el extranjero, brindamos el apoyo para administrar tu 
                                    propiedad y el pago de servicios para que tu inmueble siempre se encuentre en perfectas condiciones.
                                    <br></br>        
                                </p>
                            </div>
                            </div>
                        </div>                          
                        {/* card */}
                        <div className="card">
                            <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-3" aria-expanded="false">
                                No encuentro el inmueble que me gustaría comprar en la página
                            </h6>
                            <div id="faq-item-2-3" className="collapse" data-bs-parent="#accordion_2">
                            <div className="card-body">
                                <p>
                                   Actualmente contamos con un catalogo de más de 100 propiedades, así mismo tenemos alianzas comerciales con inmobiliarias de la zona y podemos
                                   ayudarte a buscar el inmueble que se ajuste a tus necesidades.
                                </p>
                            </div>
                            </div>
                        </div>
                        {/* card */}
                        <div className="card">
                            <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-4" aria-expanded="false">
                            Tengo dudas sobre la documentación para vender mi inmueble.
                            </h6>
                            <div id="faq-item-2-4" className="collapse" data-bs-parent="#accordion_2">
                            <div className="card-body">
                                <p>
                                    ¡Dejános esa tarea a nosotros! 
                                    <br></br>        
                                    Nuestro equipo se encuentra formado por expertos en diversos ramos jurídicos que te acompañarán durante todo el 
                                    proceso que requieras tanto para vender como para adquirir un inmueble.
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className="col-lg-4">

                <aside className="sidebar-area ltn__right-sidebar">
                    {/* Banner Widget */}
                    <div className="need-support text-center mt-10">
                    <a href="https://wa.me/525574308182?text=Quiero+ponerme+en+contacto">
                        <h2>  
                            <span>                      
                            <i className="fab fa-whatsapp" /> ¿Cómo podemos ayudarte?
                            </span>
                        </h2>
                    </a>

                    <div className="btn-wrapper mb-30 go-top">
                        <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                        </h6>

                    </div>
                    </div>

                    <div className="widget ltn__banner-widget go-top">
                        <a href="https://wa.me/525574308182?text=Quiero+ponerme+en+contacto">
                            <img src={publicUrl+"assets/img/banner/banner-3.jpg"} alt="Contactanos" />
                        </a>
                    </div>
                </aside>
                </div>
            </div>
            </div>
        </div>
        )
}
