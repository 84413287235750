import React  from 'react';

export default function Testimonial () {
    let publicUrl = process.env.PUBLIC_URL+'/'
	return( 
     <div className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70" data-bs-bg={publicUrl+"assets/img/bg/20.jpg"}>
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Nuestros misión es:</h6>
			          <h3 className="section-subtitle">Que vivas una experiencia inmobiliaria única</h3>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
			      <div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
			              Excelente servicio, desde el inicio recibí una atención personalizada y
						  siempre se preocuparon por que entendiera todo el proceso para rentar mi
						  inmueble.</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-name-designation">
			                <h5>Rodrigo Martínez</h5>
			                <label>Arrendador</label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						   La Boutique de Inmuebles es una excelente compañia, siempre 
						  obtenemos capacitación para poder brindar al cliente la mejor atención y 
						  despejar todas sus dudas. El ambiente de trabajo es muy bueno.</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-name-designation">
			                <h5>Berenice Alcantara</h5>
			                <label>Asesor de la Boutique de Inmuebles</label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Encontre una casa genial en renta, como la estaba buscando. <br></br>
						Siempre tuvieron muchas atenciones desde el momento en que
						los contacte, hasta que me entregaron la casa.</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-name-designation">
			                <h5>Ricardo Salas</h5>
			                <label>Arrendatario</label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      {/*  */}
			    </div>
			  </div>
			</div>
	)
}
