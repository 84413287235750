import React from 'react';

export default function Social() {
    return <div className="ltn__social-media">
			<ul>
				<li><a href="https://www.facebook.com/laboutiquedeinmueblesmx" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
				<li><a href="https://www.linkedin.com/in/la-boutique-de-inmuebles-528097213" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
				<li><a href="https://www.instagram.com/laboutiquedeinmueblesmx/" title="Instagram"><i className="fab fa-instagram" /></a></li>
				<li><a href="https://www.youtube.com/channel/UCUA6T2RkTC9Q8gsNkKbuSJQ/videos" title="Youtube"><i className="fab fa-youtube" /></a></li>
				<li><a href="https://www.youtube.com/channel/UCUA6T2RkTC9Q8gsNkKbuSJQ/videos" title="TikTok"><i className="fab fa-tiktok" /></a></li>

			</ul>
		</div>
}

