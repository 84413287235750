import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class UpcomingProductV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__upcoming-project-area section-bg-1--- bg-image-top pt-115 pb-65" 
			data-bs-bg={publicUrl+"assets/img/bg/22.jpg"}>
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center---">
			          <h5 className="section-title  white-color">¿Qué hacemos?</h5>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__upcoming-project-slider-1-active slick-arrow-3">
			      {/* upcoming-project-item */}
			      <div className="col-lg-12">
			        <div className="ltn__upcoming-project-item">
			          <div className="row">
			            <div className="col-lg-7">
			              <div className="ltn__upcoming-project-img">
			                <img src={publicUrl+"assets/img/product-3/1.jpg"} alt="#" />
			              </div>
			            </div>
			            <div className="col-lg-5 section-bg-1">
			              <div className="ltn__upcoming-project-info ltn__menu-widget">
						  <h1 className="ltn__secondary-color">Vendemos tu propiedad</h1>
						  <p>
			                <ul className="mt">
			                  <li>Analizamos a detalle todas las características de tu inmueble, así como un comparativo de propiedades en la zona para ayudarte a obtener el mejor beneficio de tu inmueble.</li>
			                  <li>Nuestro equipo legal se encarga de poner en orden y asesorarte con toda la información necesaria para que tu inmueble este listo para su venta.</li>
			                  <li>Realizamos una estrategia personalizada de MKT 360° para definir la mejor manera de publicitar tu inmueble, contemplando su publicación en los mejores portales inmobiliarios digitales de México y en nuestras redes sociales.</li>
			                  <li>Perfilamos clientes potenciales, para disminuir cualquier riesgo al mostrar tu inmueble.</li>
			                </ul>
							</p>
			                <div className="btn-wrapper animated go-top">
							<a href="https://wa.me/525574308182?text=Quiero+ponerme+en+contacto" className="theme-btn-1 btn btn-effect-1">Contactános</a>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      {/* upcoming-project-item */}
			      <div className="col-lg-12">
			        <div className="ltn__upcoming-project-item">
			          <div className="row">
			            <div className="col-lg-7">
			              <div className="ltn__upcoming-project-img">
			                <img src={publicUrl+"assets/img/product-3/2.jpg"} alt="#" />
			              </div>
			            </div>
			            <div className="col-lg-5 section-bg-1">
			              <div className="ltn__upcoming-project-info ltn__menu-widget">
			                <h1 className="ltn__secondary-color">Rentamos tu propiedad de manera rápida y segura</h1>
							<p>
			                <ul>
							  <li>Analizamos a detalle todas las características del inmueble, así como un comparativo de precios de la zona para brindarte la asesoría de rentarlo de forma rápida y en el mejor precio.</li>
			                  <li>Realizamos una estrategia personalizada de MKT 360° para definir la mejor manera de publicitar tu inmueble, contemplando su publicación en los mejores portales inmobiliarios digitales de México y en nuestras redes sociales.</li>
			                  <li>Perfilamos a los clientes potenciales de acuerdo a las características del inmueble.</li>
			                  <li>Rentamos con la seguridad para el propietario, asegurándonos de contar con las garantías legales.</li>
			                </ul>
							</p>
			                <div className="btn-wrapper animated go-top">
							<a href="https://wa.me/525574308182?text=Quiero+ponerme+en+contacto" className="theme-btn-1 btn btn-effect-1">Contactános</a>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      {/* upcoming-project-item */}
			      <div className="col-lg-12">
			        <div className="ltn__upcoming-project-item">
			          <div className="row">
			            <div className="col-lg-7">
			              <div className="ltn__upcoming-project-img">
			                <img src={publicUrl+"assets/img/product-3/3.jpg"} alt="#" />
			              </div>
			            </div>
			            <div className="col-lg-5 section-bg-1">
			              <div className="ltn__upcoming-project-info ltn__menu-widget">
			                <h1 className="ltn__secondary-color">¿Deseas comprar una propiedad?</h1>
							<p>
			                <ul>
			                  <li>Análizamos tus necesidades principales para la compra, para determinar las principales características e inversión para la compra de la propiedad. </li>
			                  <li>Realizamos un comparativo de créditos hipotecarios, para que puedas elegir el crédito que mas se ajuste a tus necesidades y te ayudamos a trámitarlo a través de nuestro equipo legal.</li>
			                  <li>Buscamos el inmueble ideal para tu familia, contamos con un amplio catalogo de propiedades, así como  alianzas comerciales con inmobiliarias en todas las zonas para ofrecerte las mejores opciones para tu compra.</li>
			                  <li>Te acompañamos durante todo el proceso de negociación ayudandote a presentar ofertas de compra para que obtengas la propiedad al mejor precio posible.</li>
			                </ul>
							</p>
			                <div className="btn-wrapper animated go-top">
							<a href="https://wa.me/525574308182?text=Quiero+ponerme+en+contacto" className="theme-btn-1 btn btn-effect-1">Contactános</a>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      {/*  */}
			      {/* upcoming-project-item */}
			      <div className="col-lg-12">
			        <div className="ltn__upcoming-project-item">
			          <div className="row">
			            <div className="col-lg-7">
			              <div className="ltn__upcoming-project-img">
			                <img src={publicUrl+"assets/img/product-3/4.jpg"} alt="#" />
			              </div>
			            </div>
			            <div className="col-lg-5 section-bg-1">
			              <div className="ltn__upcoming-project-info ltn__menu-widget">
			                <h1 className="ltn__secondary-color">Administramos tu propiedad</h1>
							<p>
			                <ul>
			                  <li>Analizamos a detalle todas las características del inmueble, así como un comparativo de precios de la zona para brindarte la asesoría de rentarlo de forma rápida y en el mejor precio.</li>
			                  <li>Perfilamos a los posibles inquilinos, para encontrar la familia ideal de acuerdo tu inmueble.</li>
			                  <li>Realizamos una estrategia personalizada de MKT 360° para definir la mejor manera de publicitar tu inmueble, contemplando su publicación en los mejores portales inmobiliarios digitales de México y en nuestras redes sociales.</li>
			                  <li>Aseguramos el arrendamiento mediante elaboración de póliza jurídica.</li>
			                  <li>Brindamos el apoyo para administrar tu propiedad y el pago de servicios en caso de que vivas en otra ciudad.</li>
			                </ul>		
                            </p>


			                <div className="btn-wrapper animated go-top">
			                  <a href="https://wa.me/525574308182?text=Quiero+ponerme+en+contacto" className="theme-btn-1 btn btn-effect-1">Contactános</a>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      {/*  */}

			    </div>
			  </div>
			</div>
        }
}

export default UpcomingProductV1