import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../blog-components/sidebar';
import NavBar from '../global-components/navbar';
import PageHeader from '../global-components/page-header';
import Footer from '../global-components/footer';

export default function ArticuloHogar() {

    let publicUrl = process.env.PUBLIC_URL+'/';

    return(
		<>
		<NavBar></NavBar>

		<div className={"ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image "} data-bs-bg={publicUrl+"assets/img/blog/1.jpg"}>
			<div className="container">
				<div className="row">
				<div className="col-lg-12">
					<div className="ltn__breadcrumb-inner">
					<h1 className="page-title">La BouTips</h1>
					<div className="ltn__breadcrumb-list">
						<ul>
						<li><Link to="/"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Inicio</Link></li>
						<li></li>
						</ul>
					</div>
					</div>
				</div>
				</div>
			</div>
		</div>

        <div className="ltn__page-details-area ltn__blog-details-area mb-120">
        <div className="container">
        <div className="row">
            <div className="col-lg-8">
            <div className="ltn__blog-details-wrap">
                <div className="ltn__page-details-inner ltn__blog-details-inner">
                <div className="ltn__blog-meta">
                    <ul>
                    <li className="ltn__blog-category">
                        <Link to="/blog-grid">LaBouTips</Link>
                    </li>
                    </ul>
                </div>
					<h2 className="ltn__blog-title">
						¿Cómo la luz te permite ser más feliz en casa?
					</h2>
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-author go-top">
							<i className="far fa-user" />Por: JCR - Two Arquitectura
						</li>
						<li className="ltn__blog-date">
							<i className="far fa-calendar-alt" />Noviembre, 2022
						</li>
						</ul>
					</div>
					<p>
                    </p>


					<img className="alignleft" src={publicUrl+"assets/img/blog/blog-details/4.jpg"} alt="Image1" />
					<p>
						Los ambientes en el hogar.
						Creatividad e iluminación son la materia prima para crear un sinfín de ambientes 
						en nuestro hogar. La materialidad, los estilos, el ambiente que elijamos y la apariencia 
						de los luminarios van a generar experiencias que estimulan a nuestros sentidos; 
						haciendo memorables los espacios que intervenimos.
						</p>

                    <br></br>
                    <br></br>
					<p>

						Para crear una experiencia que nos haga feliz, comenzaremos analizando el espacio. 
						¿Qué tipo de productos podemos colocar? No es lo mismo un techo falso que nos permita ocultar 
						las luminarias a una losa llena de concreto donde tendremos que colocar equipos sobrepuestos o 
						suspendidos.                    


						</p>

					<img src={publicUrl+"assets/img/blog/Luz Feliz/plafonvscolgante.png"} alt="luzempotrada" />
					<label>Toma en cuenta el tipo de instalación</label>

					<p>
						Otra cosa que debemos revisar, son las actividades o tareas que se van a realizar. 
						El ejemplo más claro es en una sala, donde hay una persona que lee. Lo más recomendado es 
						que podamos contar con iluminación localizada en el sitio donde acostumbra a leer para que 
						tenga la cantidad de luz adecuada y no genere un desgaste visual por el esfuerzo. 
						Lo anterior, además de la iluminación general del espacio. 
                    </p>

					<img src={publicUrl+"assets/img/blog/Luz Feliz/sala.jpg"} alt="sala" />
					<label>Toma en cuenta las actividades a realizar</label>


					<blockquote>
                        Es la luz esta materia que no podemos tocar, pero que nos permite ver todo lo que ella toca. Y esta energía luminosa tiene varias cualidades. Una de ellas es la apariencia en su tonalidad. 
                    </blockquote>


					<p>
						Un detalle mas a considerar, es el estilo de la ambientación o diseño interior. 
						Para que el material, la apariencia, la proporción, los formatos y dimensiones de 
						los luminarios hagan match dentro del área. Y se contribuya con la identidad del conjunto.                    
					</p>
					<img src={publicUrl+"assets/img/blog/Luz Feliz/disenointerior.jpg"} alt="diseño" />
					<label>Los materiales en el diseño son otro aspecto fundamental a considerar</label>


					<p>
						Para mí, es importante que las luminarias no deslumbren. Que la fuente de luz o el punto (LED) 
						que genera la energía lumínica esté fuera del alcance de mi vista. Esto me hace disfrutar de 
						la luz, pero sin el brillo que causa incomodidad a mis ojos.                    
					</p>

					<p>
						El tono de la luz, yo digo que tiene que ver con la velocidad con la que habitamos. 
						¿Qué es esto de la velocidad? Yo lo defino como el ritmo o agilidad que el tono de la luz 
						(temperatura de color) le imprime al espacio. Una luz cálida me invita a estar más relajado y 
						por lo tanto, mis movimientos serán más lentos. Una luz blanca hará que yo me sienta más despierto, 
						por ende, con mayor rapidez en mis movimientos.                     
					</p>


					<p>
						Ejemplo: una ludoteca infantil requiere un tono de luz que invite a los niños a estar activos, 
						en movimiento; disfrutando de las facilidades del espacio, manteniéndoles atentos a 
						las actividades que fomentan su aprendizaje e interacción social.                    
					</p>


					<blockquote>
						Lograr mis objetivos, sentirme cómodo, gozar de salud, experimentar reuniones 
						gratas con seres queridos y disfrutar de mi hogar. Son experiencias acompañadas de 
						la iluminación y que abonan a que yo me sienta feliz.                     
                    </blockquote>


					<img src={publicUrl+"assets/img/blog/Luz Feliz/ludoteca.jpg"} alt="ludoteca" />
					<label>	Ludoteca, casa club. La Vista Country Club, Puebla.
							Proyecto realizado para ZVA interiores.
					</label>


					<p>
						La luz revela formas, materiales y texturas
						La tectónica de los materiales refleja estilos y los gustos de quienes moran el espacio
                    </p>

	
                    <h4>
                        ¿Te identificas con algún ambiente en particular?
                        Compártenos tu sentir a través de tus comentarios. Queremos conocerte.
                    </h4>


					<div className="row">
						<div className="col-lg-6">
						<img src={publicUrl+"assets/img/service/31.jpg"} alt="3" />
						<label>TWOarquitectura</label>
						</div>
						<div className="col-lg-6">
						<img src={publicUrl+"assets/img/service/32.jpg"} alt="4" />
						</div>
					</div>


					</div>
					{/* blog-tags-social-media */}
					<div className="ltn__blog-tags-social-media mt-80 row">
					<div className="ltn__tagcloud-widget col-lg-8 go-top">
						<h4>Autor: JCR - TWOarquitectura  </h4>
						<ul>
						</ul>
					</div>
					</div>
					<hr />

					<hr />

				</div>
				</div>
				<Sidebar/>
			</div>
			</div>
		</div>
		<Footer></Footer>
		</>
    )
  }
