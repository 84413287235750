import React from 'react'
import { useFilterContext } from '../context/filter-context'
import Navbar from './global-components/navbar'
import PropertyGrid from './property-components/property-result'
import Footer from './global-components/footer'


const ShopLeftSidebar = () => {
    const { filtered_properties: properties } = useFilterContext()

    return (
        <main>
            <Navbar />
            <PropertyGrid properties={properties} >Property List</PropertyGrid>
            <Footer />
        </main>
    )
}
export default ShopLeftSidebar


