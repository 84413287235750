import React from 'react';
import { useParams } from "react-router-dom";
import { URL_API, API } from "../utils/constants";
import useFetch from "../hooks/useFetch";
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PropertyInfo from './property-components/property-info';
import Footer from './global-components/footer';


export default function PropertyDetails() {
    const { id } = useParams();
    const property = useFetch(`${URL_API}/property/${id.substr(0, 7)}?lang=es_ar&format=json&key=${API}`);
    const destacadas = useFetch(`${URL_API}/property/search?lang=es_ar&format=json&limit=20&offset=0&order=ASC&data=%7B+%22current_localization_id%22%3A14%2C+%22current_localization_type%22%3A%22country%22%2C+%22price_from%22%3A0%2C+%22price_to%22%3A9999999999%2C+%22operation_types%22%3A%5B%5D%2C+%22property_types%22%3A%5B3%5D%2C+%22currency%22%3A%22ANY%22%2C+%22with_custom_tags%22%3A+%5B20579%5D%7D&key=${API}`);

        if(destacadas.loading || !destacadas.result){
            return <div className="cv-spinner"><span className="spinner"></span>
        </div>
    
        }
  
        if (property.loading || !property.result) {
          return <div className="cv-spinner"><span className="spinner"></span>
          </div>
        }

        console.log(property.result);

    return <div>
        <Navbar />
        <PropertyInfo property = {property.result} destacadas = {destacadas.result}/>
        <Footer />
    </div>

}