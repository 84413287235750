import React from 'react';
import { Link } from 'react-router-dom';

export default function Error() {
    let publicUrl = process.env.PUBLIC_URL+'/'

	return <div className="ltn__404-area ltn__404-area-1 mb-120">
				<div className="container">
				<div className="row">
					<div className="col-lg-12">
					<div className="error-404-inner text-center">
						<div className="error-img mb-30">
						<img src={publicUrl+"assets/img/others/error-1.png"} alt="#" />
						</div>
						<h1 className="error-404-title d-none">404</h1>
						<h2>¡Página no encontrada!</h2>
						{/* <h3>Oops! Looks like something going rong</h3> */}
						<p>¡Oops! La página que estas buscando no existe. Es posible que haya sido modificada o eliminada.</p>
						<div className="btn-wrapper go-top">
						<Link to="/" className="btn btn-transparent"><i className="fas fa-long-arrow-alt-left" /> Regresar a Inicio</Link>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
}
