
import {
  GET_PRODUCTS_BEGIN,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_SINGLE_PRODUCT_BEGIN,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_ERROR,
} from '../actions'


const products_reducer = (state, action) => {
  if (action.type === GET_PRODUCTS_BEGIN) {
    return { ...state, properties_loading: true }
  }
  if (action.type === GET_PRODUCTS_SUCCESS) {
    const featured_properties = action.payload.filter(
      (property) => property.featured === true
    )
    
    return {
      ...state,
      properties_loading: false,
      properties: action.payload,
      featured_properties,
    }
  }

  if (action.type === GET_PRODUCTS_ERROR) {
    return { ...state, properties_loading: false, properties_error: true }
  }
  if (action.type === GET_SINGLE_PRODUCT_BEGIN) {
    return {
      ...state,
      single_property_loading: true,
      single_property_error: false,
    }
  }
  if (action.type === GET_SINGLE_PRODUCT_SUCCESS) {
    return {
      ...state,
      single_property_loading: false,
      single_property: action.payload,
    }
  }
  if (action.type === GET_SINGLE_PRODUCT_ERROR) {
    return {
      ...state,
      single_property_loading: false,
      single_property_error: true,
    }
  }
  throw new Error(`No Matching "${action.type}" - action type`)
}

export default products_reducer