import React from 'react';
import { Link } from 'react-router-dom';


export default function BlogSlider() {
    let publicUrl = process.env.PUBLIC_URL+'/'
	return (
      <div className={ "ltn__blog-area pt-15--- pb-10 go-top "}>
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-12">
		        <div className="section-title-area ltn__section-title-2--- text-center">
		          <h6 className={"section-subtitle ltn__secondary-color mt-70 "}>La BouTIPs</h6>
		          <h3 className="section-subtitle">Conoce información relevante para que disfrutes al máximo tu experiencia inmobiliaria </h3>
		        </div>
		      </div>
		    </div>
		    <div className="row  ltn__blog-slider-one-active slick-arrow-1 ltn__blog-item-3-normal">


			{/* Blog Item  Mandar llamar cada articulo */}
			<div className="col-lg-12">
			<div className="ltn__blog-item ltn__blog-item-3">
			<div className="ltn__blog-img">
			<Link to="/blog/vivir-interiorismo-nuevas-tendencias">
				<img src={publicUrl+"assets/img/blog/vivirnuevastendencias.jpg"} alt="Vivir interiorismo, nuevas tendencias" />
			</Link>
			</div>
			<div className="ltn__blog-brief">
			<div className="ltn__blog-meta">
				<ul>
				<li className="ltn__blog-author">
					<i className="far fa-user" />Zdeyna Cortés
				</li>
				<li className="ltn__blog-tags">
				<i className="fas fa-tags" />Inspiración
				</li>
				</ul>
			</div>
			<h3 className="ltn__blog-title">
				<Link to="/blog/vivir-interiorismo-nuevas-tendencias">
				Vivir el interiorismo desde las nuevas tendencias.  
				</Link></h3>
			<div className="ltn__blog-meta-btn">
				<div className="ltn__blog-meta">
				<ul>
					<li className="ltn__blog-date"><i className="far fa-calendar-alt" />Octubre, 2022</li>
				</ul>
				</div>
				<div className="ltn__blog-btn">
				<Link to="/blog/vivir-interiorismo-nuevas-tendencias">Leer artículo</Link>
				</div>
			</div>
			</div>
		</div>
			</div>

			{/* Blog Item  Mandar llamar cada articulo */}
			<div className="col-lg-12">
			<div className="ltn__blog-item ltn__blog-item-3">
			<div className="ltn__blog-img">
			<Link to="/blog/conceptos-debes-saber-credito">
				<img src={publicUrl+"assets/img/blog/3.jpg"} alt="Créditod hipotecarios, conceptos base" />
			</Link>
			</div>
			<div className="ltn__blog-brief">
			<div className="ltn__blog-meta">
				<ul>
				<li className="ltn__blog-author">
					<i className="far fa-user" />La Boutique de Inmuebles
				</li>
				<li className="ltn__blog-tags">
				<i className="fas fa-tags" />Asesoría
				</li>
				</ul>
			</div>
			<h3 className="ltn__blog-title">
				<Link to="/blog/conceptos-debes-saber-credito">
				Créditos hipotecarios, ¿Qué debes tomar en cuenta? 
				</Link></h3>
			<div className="ltn__blog-meta-btn">
				<div className="ltn__blog-meta">
				<ul>
					<li className="ltn__blog-date"><i className="far fa-calendar-alt" />Octubre, 2022</li>
				</ul>
				</div>
				<div className="ltn__blog-btn">
				<Link to="/blog/conceptos-debes-saber-credito">Leer artículo</Link>
				</div>
			</div>
			</div>
		</div>
			</div>


		      {/* Blog Item  Mandar llamar cada articulo */}
			  <div className="col-lg-12">
			<div className="ltn__blog-item ltn__blog-item-3">
			<div className="ltn__blog-img">
			<Link to="/blog/como-ser-mas-feliz-luz">
				<img src={publicUrl+"assets/img/blog/sermasfelizluz.jpg"} alt="Iluminación: Ser más feliz en casa" />
			</Link>
			</div>
			<div className="ltn__blog-brief">
			<div className="ltn__blog-meta">
				<ul>
				<li className="ltn__blog-author">
					<i className="far fa-user" />JCR
				</li>
				<li className="ltn__blog-tags">
				<i className="fas fa-tags" />Inspiración
				</li>
				</ul>
			</div>
			<h3 className="ltn__blog-title">
				<Link to="/blog/como-ser-mas-feliz-luz">
					¿Cómo la luz te permite ser más feliz en casa?
				</Link></h3>
			<div className="ltn__blog-meta-btn">
				<div className="ltn__blog-meta">
				<ul>
					<li className="ltn__blog-date"><i className="far fa-calendar-alt" />Noviembre, 2022</li>
				</ul>
				</div>
				<div className="ltn__blog-btn">
				<Link to="/blog/como-ser-mas-feliz-luz">Leer artículo</Link>
				</div>
			</div>
			</div>
		</div>
			  </div>




		      {/* Blog Item  Mandar llamar cada articulo */}
			  <div className="col-lg-12">
			<div className="ltn__blog-item ltn__blog-item-3">
			<div className="ltn__blog-img">
			<Link to="/blog/que-significa-para-mi-un-hogar">
				<img src={publicUrl+"assets/img/blog/1.jpg"} alt="Casa con Vida" />
			</Link>
			</div>
			<div className="ltn__blog-brief">
			<div className="ltn__blog-meta">
				<ul>
				<li className="ltn__blog-author">
					<i className="far fa-user" />JCR
				</li>
				<li className="ltn__blog-tags">
				<i className="fas fa-tags" />Inspiración
				</li>
				</ul>
			</div>
			<h3 className="ltn__blog-title">
				<Link to="/blog/que-significa-para-mi-un-hogar">
					¿Qué significa para mi un hogar?
				</Link></h3>
			<div className="ltn__blog-meta-btn">
				<div className="ltn__blog-meta">
				<ul>
					<li className="ltn__blog-date"><i className="far fa-calendar-alt" />Abril, 2022</li>
				</ul>
				</div>
				<div className="ltn__blog-btn">
				<Link to="/blog/que-significa-para-mi-un-hogar">Leer artículo</Link>
				</div>
			</div>
			</div>
		</div>
			  </div>

		      {/* Blog Item  Mandar llamar cada articulo */}
			  <div className="col-lg-12">
			<div className="ltn__blog-item ltn__blog-item-3">
			<div className="ltn__blog-img">
			<Link to="/blog/ambientes-creados-con-luz">
				<img src={publicUrl+"assets/img/blog/2.jpg"} alt="Ambientes con luz" />
			</Link>
			</div>
			<div className="ltn__blog-brief">
			<div className="ltn__blog-meta">
				<ul>
				<li className="ltn__blog-author">
					<i className="far fa-user" />JCR
				</li>
				<li className="ltn__blog-tags">
				<i className="fas fa-tags" />Inspiración
				</li>
				</ul>
			</div>
			<h3 className="ltn__blog-title">
				<Link to="/blog/ambientes-creados-con-luz">
				Ambientes creados con Luz 
							<br></br>
							<br></br>					
				</Link></h3>
			<div className="ltn__blog-meta-btn">
				<div className="ltn__blog-meta">
				<ul>
					<li className="ltn__blog-date"><i className="far fa-calendar-alt" />Abril, 2022</li>
				</ul>
				</div>
				<div className="ltn__blog-btn">
				<Link to="/blog/ambientes-creados-con-luz">Leer artículo</Link>
				</div>
			</div>
			</div>
		</div>
			  </div>





		    </div>
		  </div>
		</div>
    )
  }



