import React from 'react';
import { Link } from 'react-router-dom';

export default function FeaturesV1 () {

    let publicUrl = process.env.PUBLIC_URL+'/'

    return(

	 <div>
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2 text-center">
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color"></h6>
			          <h1 className="section-title">Nuestros servicios</h1>
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Vive una experiencia inmobiliaria única</h6>
			          <h6 className="section-subtitle">En la boutique de inmuebles sabemos lo importante que es un inmueble para ti, por lo que cuidamos cada detalle para que vivas una experiencia inmobiliaria única. Somos un equipo de expertos en el sector inmobiliario con más de 20 años de experiencia que te acompañara en cada detalle.</h6>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__custom-gutter justify-content-center go-top">
			      <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/22.png"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h3><Link to="/servicios">Tengo un inmueble</Link></h3>
			            <p>Te damos la seguridad de rentar o vender tu inmueble sin correr riesgos, buscando que obtengas el mejor rendimiento</p>
			            <Link className="ltn__service-btn" to="/servicios">Conocer más <i className="flaticon-right-arrow" /></Link>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/21.png"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h3><Link to="/servicios">Busco un inmueble</Link></h3>
			            <p>Contamos con un amplio catalogo de inmuebles para entrega inmediata, así como busqueda de inmuebles con características especiales </p>
			            <Link className="ltn__service-btn" to="/servicios">Conoce más <i className="flaticon-right-arrow" /></Link>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/23.png"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h3><Link to="/servicios">Requiero Asesoria</Link></h3>
			            <p>Somos un equipo experto en diversas áreas jurídicas y estamos para brindarte atención a todas tus dudas inmobiliarias</p>
			            <Link className="ltn__service-btn" to="/servicios">Conoce más <i className="flaticon-right-arrow" /></Link>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
	)
}
