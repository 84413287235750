import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Sidebar extends Component {
  render() {
   let anchor = '#'
   let imagealt = 'image'
   let publicUrl = process.env.PUBLIC_URL+'/'
    return (
		<div className="col-lg-4 go-top">
			<aside className="sidebar-area blog-sidebar ltn__right-sidebar">
				{/* Author Widget */}
				<div className="widget ltn__author-widget">
				<div className="ltn__author-widget-inner text-center">
					<img src={publicUrl+"assets/img/team/4.jpg"} alt="Image" />
					<h5>JCR</h5>
					<small>Arquitecto</small>
					<div className="product-ratting">
					<ul>
						<li><a href="#"><i className="fas fa-star" /></a></li>
						<li><a href="#"><i className="fas fa-star" /></a></li>
						<li><a href="#"><i className="fas fa-star" /></a></li>
						<li><a href="#"><i className="fas fa-star" /></a></li>
						<li><a href="#"><i className="fas fa-star" /></a></li>

					</ul>
					</div>
					<p>
						En Two arquitectura diseñamos espacios a través de una propuesta única que integra la arquitectura con la iluminación, el paisajismo y el interiorismo.					</p>
					<div className="ltn__social-media">
					<ul>
						<li><a href="https://www.facebook.com/TWOarquitectura" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
						<li><a href="https://twoarquitectura.com/" title="Página Web"><i className="fab fa-internet-explorer" /></a></li>
					</ul>
					</div>
				</div>
				</div>
				{/* Tagcloud Widget */}
				<div className="widget ltn__tagcloud-widget go-top">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Secciones</h4>
				<ul>
					<li><Link to="/propiedades">Ver todos los inmuebles</Link></li>
					<li><Link to="/servicios">Nuestros Servicios</Link></li>
					<li><Link to="/contacto">Contáctanos</Link></li>
				</ul>
				</div>
			</aside>
		</div>
    )
  }
}

export default Sidebar;
