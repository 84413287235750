import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const MapContainer = (props) => {

    
    const latitud = props.lat;
    const longitud = props.long;

    console.log(props.lat);

    const mapStyles = {        
        height: "100vh",
        width: "100%"};
      
      const defaultCenter = {
        lat: latitud, lng: longitud
      }
    
      const locations = [
        {
          name: "Inmueble",
          location: { 
            lat: latitud,
            lng: longitud
          },
        },
      ];

  
  return (
     <LoadScript
       googleMapsApiKey='AIzaSyAfKQ5i-zNJh7HmbZ_LIFRqPWp-BddSLZM'>
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={16}
          center={defaultCenter}>
         {
            locations.map(item => {
              return (
              <Marker key={item.name} position={item.location}/>
              )
            })
         }
     </GoogleMap>
     </LoadScript>
  )
}

export default MapContainer;