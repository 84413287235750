import React from 'react';

export default function Map() {
    return(
        <>
        <div className="google-map mb-120">
			<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1880.4190958707509!2d-99.23587916336821!3d19.505596133999262!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1ecd5a6a0d18e113!2sLa%20Boutique%20de%20Inmuebles!5e0!3m2!1ses-419!2smx!4v1651257446318!5m2!1ses-419!2smx" width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
		</div>
        </>
    )
}
