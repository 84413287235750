import React, { Component } from 'react';

class ContactInfo extends Component {

    render() {

    let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__contact-address-area mb-90">
				<div className="container">

				<div className="row">

				<div className="col-lg-12 align-self-center">
					<div className="about-us-info-wrap">
						<div className="section-title-area ltn__section-title-2--- mb-20">
						<h1 className="section-title">La Boutique de Inmuebles 
							<span>.</span></h1>
							<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Estamos a tus órdenes en los siguientes medios.</h6>
						<p></p>
						</div>
						<ul className="ltn__list-item-half clearfix">
						</ul>
					</div>
				</div>
				</div>

				<div className="row">
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="ltn__contact-address-icon">
						<img src={publicUrl+"assets/img/icons/10.png"} alt="Icono1" />
						</div>
						<h3>Email</h3>
						<p>informes@laboutiquedeinmuebles.com <br /><br />
						</p>
					</div>
					</div>
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="ltn__contact-address-icon">
						<img src={publicUrl+"assets/img/icons/11.png"} alt="Icon02" />
						</div>
						<h3>Teléfono</h3>
						<p>(+52) 5574308182 <br /><br /> </p>
					</div>
					</div>
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="ltn__contact-address-icon">
						<img src={publicUrl+"assets/img/icons/12.png"} alt="Icon03" />
						</div>
						<h3>Dirección</h3>
						<p>Cto. Médicos #6-Piso 2, Cd.Satélite  <br />
						Naucalpan de Juárez, Méx.</p>
					</div>
					</div>
				</div>
				</div>
			</div>
        }
}

export default ContactInfo