import React from 'react';
import { Link } from 'react-router-dom';


export default function AboutV2(props) {
	const { principal } = props;
	const max = principal.result.objects.length - 1;
	const aleatorio = Math.floor(Math.random() * max);
	const { id, publication_title, address, description, suite_amount, bathroom_amount, parking_lot_amount, total_surface, surface, photos } = principal.result.objects[aleatorio];
	const palabra = "*";
	const index = description.indexOf(palabra);
    const slug = publication_title.replace(/\s+/g, '-');


	return (
    	<div className="ltn__about-us-area pt-120 pb-90 ">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-info-wrap">
			          <div className="section-title-area ltn__section-title-2--- mb-30">
			            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Inmueble Destacado</h6>
			            <h1 className="section-title"><Link to={`/p/${id}-${slug}`}>{address}</Link></h1>
							{(index > 0) ? 
							<p>
								{description.substr(0,index)}					
							</p>
							:
							<p>
								{description.substr(0,600)}					
							</p>						
							}
			          </div>
			          <ul className="ltn__list-item-2 ltn__list-item-2-before ltn__flat-info">
			            <li><span>{suite_amount} <i className="flaticon-bed" /></span>Recámaras</li>
			            <li><span>{bathroom_amount} <i className="flaticon-clean" /></span>Baños</li>
			            <li><span>{parking_lot_amount} <i className="flaticon-car" /></span>Estacionamiento</li>
			            <li><span>{Number(total_surface)} <i className="flaticon-house-1" /></span>Construcción mts2</li>
			            <li><span>{Number(surface)} <i className="flaticon-square-shape-design-interface-tool-symbol" /></span>Total mts2</li>
			          </ul>

			          <ul className="ltn__list-item-2 ltn__list-item-2-before--- ltn__list-item-2-img mb-50">
			            <li>  
			              <a href={photos[1].image} data-rel="lightcase:myCollection">
			                <img src={photos[1].image} alt={photos[1].order} />
			              </a>
			            </li>
			            <li>
			              <a href={photos[2].image} data-rel="lightcase:myCollection">
			                <img src={photos[2].image} alt={photos[2].order} />
			              </a>
			            </li>
			            <li>
			              <a href={photos[3].image} data-rel="lightcase:myCollection">
			                <img src={photos[3].image} alt={photos[3].order} />
			              </a>
			            </li>
			            <li>
			              <a href={photos[4].image} data-rel="lightcase:myCollection">
			                <img src={photos[4].image} alt={photos[4].order} />
			              </a>
			            </li>
			            <li>
			              <a href={photos[5].image} data-rel="lightcase:myCollection">
			                <img src={photos[5].image} alt={photos[5].order} />
			              </a>
			            </li>
			            <li>
			              <a href={photos[6].image} data-rel="lightcase:myCollection">
			                <img src={photos[6].image} alt={photos[6].order} />
			              </a>
			            </li>
			          </ul>

					  {photos.map(foto=>(
							<a href={foto.image} data-rel="lightcase:myCollection" alt={foto.order} ></a>
							))}              

			        </div>
			      </div>

			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-img-wrap about-img-right">
					<Link to={`/p/${id}-${slug}`}><img src={photos[0].image} alt="Inmueble" /></Link>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
		)
}
