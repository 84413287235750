import React from 'react';
import {useState} from 'react';
import { Link } from 'react-router-dom';
import GoogleMap from '../section-components/google-map';

export default function PropertyInfo (props) {
	const propiedad  = props.property;
	const destacadas = props.destacadas.objects;
	const amenidades = propiedad.tags.filter(tag => tag.type === 3)
	const arreglo_amenidades = [[], [], []] 
	const wordsPerLine = Math.ceil(amenidades.length / 3)
	let MostrarRenta  = null;
	let MostrarVenta  = null;
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMessage] = useState("");
	const [respuesta, setRespuesta] = useState("");

	const Venta = propiedad.operations.filter(operation => operation.operation_type === "Venta")
	const Renta = propiedad.operations.filter(operation => operation.operation_type === "Alquiler")

	const handleSubmit = async (e) => {

		setRespuesta("");

		e.preventDefault();
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			name: name,
			email: email,
			phone: phone,
			text: message,
			tags:["LaBoutiquedeInmuebles", "Formulario de Propiedad"],
		  });

		
		var requestOptions = {
		  method: 'POST',
		  headers: myHeaders,
		  body: raw,
		  redirect: 'follow'
		};

				
		try {
		   const res = await fetch("https://tokkobroker.com/api/v1/webcontact/?key=5b97b13cc16cd98557954ad69bb021d57ee86456", requestOptions)

			if (res.status === 201) {
				setName("");
				setEmail("");
				setPhone("");
				setMessage("");
				setRespuesta("Mensaje enviado satisfactoriamente");
			}		  
			} catch (err) {
				console.log('error', err);
				setRespuesta("Ocurrio algún error, vuelve a intentar enviar la información");
		}
	};
	  

	if (Renta.length > 0){
		MostrarRenta  = 'SI';
	}
	if (Venta.length > 0){
		MostrarVenta  = 'SI';
	}
	
	amenidades.forEach(function(amenidad) {
	if (amenidad.name === 'Cloaca') {
		amenidad.name= 'Alcantarilla';
	}
	if (amenidad.name === 'Altillo') {
		amenidad.name= 'Ático';
	}
	if (amenidad.name === 'Baulera') {
		amenidad.name= 'Armario';
	}
	if (amenidad.name === 'Amoblado') {
		amenidad.name= 'Amueblado';
	}
	if (amenidad.name === 'Quincho') {
		amenidad.name= 'Asador';
	}
	if (amenidad.name === 'Solarium') {
		amenidad.name= 'Terraza';
	}
	if (amenidad.name === 'Family') {
		amenidad.name= 'Familiar';
	}
	if (amenidad.name === 'Baulera General') {
		amenidad.name= 'Armario';
	}
	if (amenidad.name === 'Baulera Individual') {
		amenidad.name= 'Armario';
	}
	if (amenidad.name === 'Calefón') {
		amenidad.name= 'Boiler';
	}
	if (amenidad.name === 'Mosquitera') {
		amenidad.name= 'Mosquiteros';
	}
	if (amenidad.name === 'Frigorífico') {
		amenidad.name= 'Cámara de Refrigeración';
	}
	if (amenidad.name === 'Amenities') {
		amenidad.name= 'Diversas Amenidades';
	}
	});

	for (let line = 0; line < 3; line++) {
	  for (let i = 0; i < wordsPerLine; i++) {
		const value = amenidades[i + line * wordsPerLine]
		if (!value) continue 		
		arreglo_amenidades[line].push(value)
	}




}


    return 	(
		<>

				<div className={"ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image mb-0"} data-bs-bg={propiedad.photos[0].image}>
					<div className="container">
						<div className="row">
						<div className="col-lg-12">
							<div className="ltn__breadcrumb-inner">
							<div className="ltn__breadcrumb-list">
								<ul>
								</ul>
							</div>

							</div>
						</div>
						</div>
					</div>
				</div>


			<div className="ltn__shop-details-area pb-10">
				<div className="container">
				<div className="row">
					<div className="col-lg-8 col-md-12">

					<div className="ltn__shop-details-inner ltn__page-details-inner mb-60 mt-60">
						<div className="ltn__blog-meta">
						<ul>

						<div className="ltn__breadcrumb-list">
								<ul>
								<li><Link to="/"><span className="ltn__secondary-color"><i className="fas fa-home" /></span>Ir a Inicio</Link></li>
								<h1 className="page-title">{propiedad.address}</h1>
								</ul>
							</div>


						</ul>
						</div>

						<h4 className="title-2">Galeria de Fotos</h4><h6>Da clic sobre una imágen para ampliarla.</h6>
						<div className="ltn__img-slider-area">
							<div className="container-fluid">
							<div className="row ltn__image-slider-5-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all">
							{propiedad.photos.map(foto=>(
								<div className="col-lg-12">
								<div className="ltn__img-slide-item-4">
									<a href={foto.image} data-rel="lightcase:myCollection">
									<img src={foto.image} alt={foto.image} />
									</a>
								</div>
								</div>
							))}                  
							</div>
							</div>
							</div>


						<h4 className="title-2">Detalles del Inmueble</h4>  
						<label><span className="ltn__secondary-color"><i className="flaticon-pin" />
						</span> {propiedad.location.short_location} </label>
						<div className="property-detail-info-list section-bg-1 clearfix">                          
						<ul>
							<li><label>Tipo de Inmueble: </label> <span>{propiedad.type.name}</span></li>
							<li><label>Plantas:</label> <span>{propiedad.floors_amount}</span></li>
							<li><label>Condición:</label> <span>{propiedad.property_condition}</span></li>
						</ul>
						<ul>
							{propiedad.surface!=0 && <li><label>Sup. Terreno:</label> <span>{propiedad.surface} mt2</span></li>}
							{propiedad.total_surface!=0 && <li><label>Sup. Construcción:</label> <span>{propiedad.total_surface} mt2</span></li>}
							{propiedad.roofed_surface!=0 && <li><label>Sup. Cubierta:</label> <span>{propiedad.roofed_surface} mt2</span></li>}

						</ul>
						</div>


						<h4 className="title-2">Precio del inmueble</h4>

								{MostrarVenta  && 
									<ul>
									<li className="ltn__blog-category">
									<Link  to="#">
										VENTA: ${Venta[0].prices[0].price.toLocaleString("es-MX")} {Venta[0].prices[0].currency}
									</Link>
									</li>
									</ul>}

							
								{MostrarRenta  && 
									<ul>
									<li className="ltn__blog-category">
									<Link className="bg-green" to="#">
										<p>RENTA: ${Renta[0].prices[0].price.toLocaleString("es-MX")} {Renta[0].prices[0].currency}</p>
									</Link>
									</li>
									</ul>}
									

						<h4 className="title-2">Información básica del Inmueble</h4>
						<div className="property-detail-feature-list clearfix mb-45">                            
						<ul>
							<li>
								<div className="property-detail-feature-list-item">
									<i className="flaticon-double-bed" />
									<div><h6>Recámaras</h6><small>{propiedad.suite_amount}</small></div>
								</div>
							</li>


							{propiedad.tags.find(tag => tag.name === "Comedor") ? 
							<li>
								<div className="property-detail-feature-list-item">
									<i className="flaticon-dining-table-with-chairs" />
									<div><h6>Comedor</h6><small>Si</small></div>
								</div>
							</li>
							:
							<li>
								<div className="property-detail-feature-list-item">
									<i className="flaticon-dining-table-with-chairs" />
									<div><h6>Comedor</h6><small>No</small></div>
								</div>
							</li>
							}

							{propiedad.tags.find(tag => tag.name === "Living") ? 
							<li>
								<div className="property-detail-feature-list-item">
									<i className="flaticon-armchair" />
									<div><h6>Sala</h6><small>Si</small></div>
								</div>
							</li>
							:
							<li>
								<div className="property-detail-feature-list-item">
									<i className="flaticon-armchair" />
									<div><h6>Sala</h6><small>No</small></div>
								</div>
							</li>
							}

							<li>
								<div className="property-detail-feature-list-item">
									<i className="flaticon-garage" />
									<div><h6>Estacionamiento</h6><small>{propiedad.parking_lot_amount}</small></div>
								</div>
							</li>
							<li>
								<div className="property-detail-feature-list-item">
									<i className="flaticon-bathtub" />
									<div><h6>Baños</h6><small>{propiedad.bathroom_amount}</small></div>
								</div>
							</li>

							{propiedad.tags.find(tag => tag.name === "Jardín") ? 
							<li>
								<div className="property-detail-feature-list-item">
									<i className="flaticon-park" />
									<div><h6>Jardín</h6><small>Si</small></div>
								</div>
							</li>
							:
							<li>
								<div className="property-detail-feature-list-item">
									<i className="flaticon-park" />
									<div><h6>Jardín</h6><small>No</small></div>
								</div>
							</li>
							}
							
							{propiedad.surface!=0 && 
							<li>
								<div className="property-detail-feature-list-item">
									<i className="flaticon-measure" />
									<div><h6>Sup. Terreno </h6><small>{propiedad.surface} mt2</small></div>
								</div>
							</li>
							}

							{propiedad.total_surface!=0 && 
							<li>
								<div className="property-detail-feature-list-item">
									<i className="flaticon-measure" />
									<div><h6>Sup. Contrucción</h6><small>{propiedad.total_surface} mt2</small></div>
								</div>
							</li>
							}
						</ul>
						</div>





						{/* Mostrar Amenidades sino vienen en blanco */}
						{amenidades.length != 0 ? 
						<div>
							<h4 className="title-2 mb-10">Amenidades</h4>
							<div className="property-details-amenities mb-60">
							<div className="row">
								<div className="col-lg-4 col-md-6">
								<div className="ltn__menu-widget">
									<ul>
										{/* Llamar el componente para realizar el llenado */}
										{arreglo_amenidades[0].map(amenidad=>(
										<li>
										<label className="checkbox-item">{amenidad.name}
										<input type="checkbox" defaultChecked="checked" disabled/>
										<span className="checkmark" />
										</label>
										</li>
										))}    
										{/*  */}
									</ul>
								</div>
								</div>
								<div className="col-lg-4 col-md-6">
								<div className="ltn__menu-widget">
									<ul>
										{/* Llamar el componente para realizar el llenado */}
										{arreglo_amenidades[1].map(amenidad=>(
										<li>
										<label className="checkbox-item">{amenidad.name}
										<input type="checkbox" defaultChecked="checked" disabled/>
										<span className="checkmark" />
										</label>
										</li>
										))}    
										{/*  */}
									</ul>
								</div>
								</div>
								<div className="col-lg-4 col-md-6">
								<div className="ltn__menu-widget">
									<ul>
										{/* Llamar el componente para realizar el llenado */}
										{arreglo_amenidades[2].map(amenidad=>(
										<li>
										<label className="checkbox-item">{amenidad.name}
										<input type="checkbox" defaultChecked="checked" disabled/>
										<span className="checkmark" />
										</label>
										</li>
										))}    
										{/*  */}
									</ul>
								</div>
								</div>
							</div>
							</div>
						</div>
							:
							<div></div>
						}

						{/* Mostrar el video */}
						{propiedad.videos.length >0 ? 
							<div>
								<h4 className="title-2">Video</h4>
								<div className="ltn__video-bg-img ltn__video-popup-height-500 bg-overlay-black-50 bg-image mb-60" 
									data-bs-bg={propiedad.photos[1].image}
								>
								<a className="ltn__video-icon-2 ltn__video-icon-2-border---" 
									href={propiedad.videos[0].player_url}
									data-rel="lightcase:myCollection">
								<i className="fa fa-play" />
								</a>
								</div>
							</div>
							:
							<div></div>
						}

						<h4 className="title-2">Descripción</h4>
						<p> 
							<div dangerouslySetInnerHTML={{__html: propiedad.rich_description}}></div>
						</p>

						<h4 className="title-2">Ubicación</h4>
						<GoogleMap lat = {parseFloat(propiedad.geo_lat)}  long = {parseFloat(propiedad.geo_long)} />


					</div>

					</div>
					<div className="col-lg-4 mt-60">
					<aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
						{/* Author Widget */}
						<div className="widget ltn__author-widget">
						<div className="ltn__author-widget-inner text-center">
							<img src={propiedad.producer.picture}  alt={propiedad.producer.name} />
							<h5>{propiedad.producer.name}</h5>
							<small>Asesor de la Boutique de Inmuebles</small>
						</div>
						</div>

						{/* Form Widget */}
						<div className="widget ltn__form-widget">
						<h4 className="ltn__widget-title ltn__widget-title-border-2">Enviar Mensaje</h4>
						<form onSubmit={handleSubmit}>
							<input 
								type="text" 
								name="yourname"
								value = {name} 
								placeholder="Introduce tu nombre*" 
								onChange={(e) => setName(e.target.value)}
							/>
							<input 
								type="email" 
								value ={email}
								name="youremail" 
								placeholder="Introduce tu email*" 
								onChange={(e) => setEmail(e.target.value)}
							/>
							<input 
								type="text"
								value = {phone} 
								name="yourphone" 
								placeholder="Introduce tu número celular*"
								onChange={(e) => setPhone(e.target.value)} 
							/>
							<textarea 
								name="yourmessage"
								value ={message} 
								placeholder="Introduce tu Mensaje..." 
								defaultValue={""} 
								onChange={(e) => setMessage(e.target.value)}
							/>
							<button type="submit" className="btn theme-btn-1">
								Enviar
							</button>
							<hr></hr>
							<div className="ltn__widget-title ltn__widget-title-border-2">{respuesta ? <p>{respuesta}</p> : null}</div>
						</form>
						</div>

						{/* Popular Product Widget  
						<div className="widget ltn__popular-product-widget">       
							<h4 className="ltn__widget-title ltn__widget-title-border-2">Propiedades populares</h4>                     
								<div className="row ltn__popular-product-widget-active slick-arrow-1">

							{destacadas.map(destacada=>(
								<Propiedades key={destacada.id} destacada = {destacada} />
							))}                  
							</div>
						</div>					
						*/}

						{/* Social Media Widget */}
						<div className="widget ltn__social-media-widget">
						<h4 className="ltn__widget-title ltn__widget-title-border-2">Siguenos en redes sociales</h4>
						<div className="ltn__social-media-2">
							<ul>
							<li><a href="https://www.facebook.com/laboutiquedeinmueblesmx" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
							<li><a href="https://www.linkedin.com/in/la-boutique-de-inmuebles-528097213" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
							<li><a href="https://www.instagram.com/laboutiquedeinmueblesmx/" title="Instagram"><i className="fab fa-instagram" /></a></li>
							</ul>
						</div>
						</div>
					</aside>
					</div>
				</div>
				</div>
			</div>
</>
)
}


{/* Popular Product Widget  

function Propiedades(props){
	const titulo = props.destacada.publication_title;
    const slug = titulo.replace(/\s+/g, '-');
    const tipoOperacion = props.destacada.operations[0].operation_type;
    const montoOperacion = props.destacada.operations[0].prices[0].price;


	return (
				<div className="col-12">
					<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
						<div className="product-img go-top">
							<Link to={`/p/${props.destacada.id}-${slug}`}><img src={props.destacada.photos[0].image} alt={props.destacada.id} /></Link>
							<div className="real-estate-agent">
								<div className="agent-img">
									<Link to={`/p/${props.destacada.id}-${slug}`}><img src={props.destacada.producer.picture} alt={props.destacada.producer.picture} /></Link>
								</div>
							</div>
						</div>
						<div className="product-info">
								<div className="product-price"><span>{props.destacada.operations[0].operation_type} ${montoOperacion.toLocaleString("es-MX")} {props.destacada.operations[0].prices[0].currency}</span></div>

								<h2 className="product-title"><Link to="/shop">{props.destacada.address}</Link></h2>
								<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
									<li><span>{props.destacada.suite_amount} </span>Recámaras</li>
									<li><span>{props.destacada.bathroom_amount} </span>Baños</li>
									<li><span>{props.destacada.total_surface} </span>mt2</li>
								</ul>
						</div>
					</div>
				</div>
    ) 
}
*/}
